enum PaperlessCommunicationTestIds {
  PAPERLESS_COMMUNICATION_DIALOG = 'paperlessCommunicationDialog',
  PAPERLESS_COMMUNICATION_DIALOG_CONFIRM_CTA = 'paperlessCommunicationDialogConfirmCta',
  PAPERLESS_COMMUNICATION_DIALOG_SKIP_CTA = 'paperlessCommunicationDialogSkipCta',
  PAPERLESS_COMMUNICATION_DIALOG_SUCCESS_TOAST = 'paperlessCommunicationDialogSuccessToast',
  PAPERLESS_COMMUNICATION_DIALOG_PAPER_KEPT_TOAST = 'paperlessCommunicationDialogPaperKeptToast',
  PAPERLESS_COMMUNICATION_DIALOG_FAILED_TOAST = 'paperlessCommunicationDialogFailedToast',

  EMAIL_COMMUNICATION_CHANNELS_DIALOG = 'emailCommunicationChannelsDialog',
  EMAIL_COMMUNICATION_CHANNELS_DIALOG_SELECT_CTA = 'emailCommunicationChannelsDialogSelectCta',

  PAPERLESS_COMMUNICATION_PROMPT = 'paperlessCommunicationPrompt',
  PAPERLESS_COMMUNICATION_PROMPT_LINK = 'paperlessCommunicationPromptLink'
}

export default PaperlessCommunicationTestIds
