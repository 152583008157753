<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="32"
      height="32"
      rx="16"
      fill="#D1D1D1"
    />
    <g clip-path="url(#clip0_2160_10838)">
      <circle
        cx="15.9996"
        cy="12.8"
        r="6.4"
        fill="#F2F2F2"
      />
      <path
        d="M4.2666 34.1333C4.2666 33.1428 4.2666 32.6476 4.29948 32.2298C4.71115 26.9991 8.86569 22.8445 14.0964 22.4329C14.5142 22.4 15.0094 22.4 15.9999 22.4C16.9904 22.4 17.4857 22.4 17.9035 22.4329C23.1342 22.8445 27.2887 26.9991 27.7004 32.2298C27.7333 32.6476 27.7333 33.1428 27.7333 34.1333V35.2H4.2666V34.1333Z"
        fill="#F2F2F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_2160_10838">
        <rect
          width="32"
          height="32"
          rx="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
