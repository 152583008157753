import type { ComputedRef } from 'vue'

export enum Cache {
  Disabled = 0,
  Enabled = 1
}

export interface UserPersonalDataComposable<T> {
  fetch: (cacheState?: Cache) => Promise<T>
  isLoading: ComputedRef<boolean>
}
