import get from 'lodash/get'

import useToken from '~/middleware/utils/useToken'
import useAppConfig from '~/composables/useAppConfig/index'

const PASSWORD_RESET_CLAIM = 'https://custom.ciam.com/last_password_reset'

const usePasswordResetClaim = async (): Promise<string | undefined> => {
  const appConfig = useAppConfig()
  const getTokenPromise = useToken(appConfig)
  const token = await getTokenPromise

  if (!token) {
    return
  }

  const tokenContent = JSON.parse(atob(token.split('.')[1]))

  return get(tokenContent, PASSWORD_RESET_CLAIM)
}

export default usePasswordResetClaim
