import { GLOBAL_EVENTS, GLOBAL_EVENTS_GROUP, useGlobalEvents } from '~/composables/utils/useGlobalEvents'

type TenantDefinedUnobserver = () => any

export interface TenantDefinedObserverComposable {
  observe: (fn: () => any) => TenantDefinedUnobserver
}

const observables: any[] = []

const eventGroup = GLOBAL_EVENTS_GROUP.USER
const eventBus = useGlobalEvents(eventGroup)

function listener(event: string) {
  if (event === GLOBAL_EVENTS.USER_TENANT_DEFINED)
    observables.forEach((observable) => observable())
}

eventBus.on(listener)

const useTenantDefinedObserver = (): TenantDefinedObserverComposable => {
  const unobserveFactory = (index: number) => {
    return () => {
      observables.splice(index, 1)
    }
  }

  const observe: (fn: () => any) => TenantDefinedUnobserver = (fn) => {
    observables.push(fn)

    const observableIndex = observables.length - 1
    return unobserveFactory(observableIndex)
  }

  return {
    observe
  }
}

export default useTenantDefinedObserver
