<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="48"
      height="48"
      rx="10.68"
      fill="#000099"
    />
    <path
      d="M0 19.2C0 12.4794 0 9.11905 1.30792 6.55211C2.4584 4.29417 4.29417 2.4584 6.55211 1.30792C9.11905 0 12.4794 0 19.2 0H28.8C35.5206 0 38.8809 0 41.4479 1.30792C43.7058 2.4584 45.5416 4.29417 46.6921 6.55211C48 9.11905 48 12.4794 48 19.2V28.8C48 35.5206 48 38.8809 46.6921 41.4479C45.5416 43.7058 43.7058 45.5416 41.4479 46.6921C38.8809 48 35.5206 48 28.8 48H19.2C12.4794 48 9.11905 48 6.55211 46.6921C4.29417 45.5416 2.4584 43.7058 1.30792 41.4479C0 38.8809 0 35.5206 0 28.8V19.2Z"
      fill="#000099"
    />
    <path
      d="M16.6503 24.3898C16.2956 24.009 16.0591 23.5519 16.0591 22.9806C16.0591 21.876 16.9655 21 18.1084 21H21.8128V22.1807H18.1084C17.6749 22.1807 17.2808 22.5235 17.2808 22.9806C17.2808 23.3995 17.6355 23.7804 18.1084 23.7804H20.5518V24.9612H18.1084C17.6749 24.9612 17.2808 25.3039 17.2808 25.761C17.2808 26.18 17.6355 26.5608 18.1084 26.5608H21.8128V27.7416H18.1084C16.9655 27.7416 16.0591 26.8655 16.0591 25.761C16.0591 25.2659 16.2562 24.7707 16.6503 24.3898ZM26.1084 24.1994C26.5419 24.1994 26.936 24.5422 26.936 24.9992V27.7797H28.1577V24.9992C28.1577 23.8947 27.2513 23.0187 26.1084 23.0187H22.7981V27.7797H24.0197V24.1994H26.1084ZM40.3744 27.2083C40.7685 27.5511 41.3202 27.7797 41.8719 27.7797C43.0148 27.7797 44 26.8655 44 25.761V21.0381H42.7784V25.761C42.7784 26.18 42.3449 26.5989 41.9114 26.5989C41.4779 26.5989 41.0444 26.2181 41.0444 25.761V23.0187H39.7833V25.761C39.7833 26.18 39.3498 26.5989 38.9163 26.5989C38.4828 26.5989 38.0493 26.2181 38.0493 25.761V21.0381H36.8276V25.761C36.8276 26.8655 37.8128 27.7797 38.9557 27.7797C39.468 27.7797 40.0197 27.5511 40.3744 27.2083ZM30.6404 22.2188H33.5173C33.9508 22.2188 34.3449 22.5616 34.3449 23.0187C34.3449 23.4376 33.9902 23.8185 33.5173 23.8185H31.8621V24.9992H33.5173C33.9508 24.9992 34.3449 25.342 34.3449 25.7991C34.3449 26.2181 33.9902 26.5989 33.5173 26.5989H29.3793V27.7797H33.4779C34.6207 27.7797 35.5271 26.9036 35.5271 25.7991C35.5271 25.2659 35.2907 24.7707 34.936 24.3898C35.2907 24.009 35.5271 23.5519 35.5271 22.9806C35.5271 21.876 34.6207 21 33.4779 21H29.3793V25.3801H30.601L30.6404 22.2188Z"
      fill="white"
    />
    <path
      d="M14.1281 24.9231V23.8947H4.51232C4.23645 23.8947 4 24.1232 4 24.3899C4 24.6565 4.23645 24.885 4.51232 24.885H14.1281V24.9231Z"
      fill="#FF9900"
    />
  </svg>
</template>
