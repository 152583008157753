<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppFooter'
})
</script>

<script setup lang="ts">
import TestIds from '~cypress/types/testIds'
import { Routes, ExternalRoutes } from '~/routing/types'
const { t } = useI18n()
</script>

<template>
  <footer
    :data-testId="TestIds.APP_FOOTER"
    class="footer-container"
  >
    <div class="footer-content">
      <div class="footer__row">
        <div class="footer__column">
          <div class="footer-links q-py-sm footer__row">
            <div class="footer__column s2">
              <a
                :href="ExternalRoutes.ENBW_IMPRESSUM"
                target="_blank"
              >
                {{ t('layout.footer.impressum') }}
              </a>
            </div>
            <div class="footer__column s2">
              <router-link
                :to="Routes.DATA_PROTECTION"
                :data-testId="TestIds.DATA_PROTECTION_FOOTER_LINK"
              >
                {{ t('layout.footer.privacy') }}
              </router-link>
            </div>
            <div class="footer__column s2">
              <a
                :href="ExternalRoutes.ENBW_MEK"
                target="_blank"
              >
                {{ t('layout.footer.about-mek') }}
              </a>
            </div>
          </div>
        </div>

        <div class="footer__column">
          <div class="footer-logo footer__row">
            <a
              :href="ExternalRoutes.ENBW"
              target="_blank"
              class="s2"
            >
              <IconLogoFooter
                width="181.5"
                height="32"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import '~vars';

.footer__row {
  display: flex;
  flex-wrap: wrap;
}

.footer__column {
  flex: 0 0 auto;
}

.footer-container {
  margin-top: auto;
  background-color: $light-gray;

  .footer-content {
    margin: 0 auto;
    padding: 24px;
    background-color: $light-gray;

    .footer-links {
      a:focus,
      a:hover {
        color: $primary-blue;
      }
    }

    .footer__row {
      justify-content: center;
    }

    .footer__column {
      width: 100%;
      text-align: center;
    }

    .footer-links .footer__column:not(:last-child) {
      margin-bottom: 24px;
    }

    .footer-logo {
      margin-top: 24px;

      a {
        height: 32px;
      }
    }
  }

  @media (min-width: 352px) {
    .footer-content {
      .footer__column {
        text-align: left;
      }

      .footer-links {
        justify-content: space-between;
      }

      .footer-links .footer__column {
        width: auto;
      }

      .footer-logo {
        margin-top: 0;
        justify-content: flex-end;
      }
    }
  }

  @media (min-width: 600px) {
    .footer-content {
      padding-top: 16px;
      padding-bottom: 16px;

      .footer__row {
        justify-content: space-between;
      }

      .footer__column {
        width: auto;
      }

      .footer-links {
        width: 337px;
      }

      .footer-links .footer__column:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 1028px) {
    .footer-content {
      max-width: 1028px;

      .footer-links {
        width: 358px;
      }
    }
  }
}
</style>
