import { defineStore } from 'pinia'

import { StoreKeys } from '~/stores/types'
import { DialogId } from '~/composables/dialogs/useDialog/types'

export interface State {
  isOpen: Record<string, boolean>

  isLogoutDialogOpen: boolean,
  isLogoutPopupOpen: boolean,
  isHelpfulInfoDialogOpen: boolean,
  helpfulInfoDialogType: number,
  isSelectPhoneDialogOpen: boolean,
  isSelectEmailDialogOpen: boolean,
  isSelectContractDialogOpen: boolean,
  isContactPhoneBulkEditDialog: boolean,
  isProfileDeletionDialogOpen: boolean,
  isNewPhoneDialogOpen: boolean,
  isNewEmailDialogOpen: boolean,
  isPhonePinDialogOpen: boolean,
  isSelectTenantDialogOpen: boolean,
  isSenecUnavailableDialogOpen: boolean,
  dialogHeading: string,
}

interface Actions {
  openLogoutDialog: () => void
  closeLogoutDialog: () => void
  openLogoutPopup: () => void
  closeLogoutPopup: () => void
  openHelpfulInfoDialog: (type: number) => void
  closeHelpfulInfoDialog: () => void
  openSelectPhoneDialog: () => void
  closeSelectPhoneDialog: () => void
  openSelectEmailDialog: () => void
  closeSelectEmailDialog: () => void
  openSelectContractDialog: () => void
  closeSelectContractDialog: () => void
  openContactPhoneBulkEditDialog: () => void
  closeContactPhoneBulkEditDialog: () => void
  openProfileDeletionDialog: () => void
  closeProfileDeletionDialog: () => void
  openNewPhoneDialog: () => void
  closeNewPhoneDialog: () => void
  openNewEmailDialog: () => void
  closeNewEmailDialog: () => void
  openPhonePinEditDialog: () => void
  closePhonePinEditDialog: () => void
  openSelectTenantDialog: () => void
  closeSelectTenantDialog: () => void
  openSenecTenantUnavailableDialog: () => void
  openDialog: (dialogId: DialogId) => void
  closeDialog: (dialogId: DialogId) => void
  setDialogHeading: (heading: string) => void
}

const useDialogsStore = defineStore<StoreKeys, State, any, Actions>(StoreKeys.DIALOGS, {
  state: () => ({
    isOpen: {},

    isLogoutDialogOpen: false,
    isLogoutPopupOpen: false,
    isHelpfulInfoDialogOpen: false,
    helpfulInfoDialogType: 0,
    isSelectPhoneDialogOpen: false,
    isSelectEmailDialogOpen: false,
    isSelectContractDialogOpen: false,
    isContactPhoneBulkEditDialog: false,
    isProfileDeletionDialogOpen: false,
    isNewPhoneDialogOpen: false,
    isNewEmailDialogOpen: false,
    isPhonePinDialogOpen: false,
    isSelectTenantDialogOpen: false,
    isSenecUnavailableDialogOpen: false,
    dialogHeading: ''
  }),

  getters: {
    isDialogOpen(state: State) {
      return (dialogId: DialogId) => state.isOpen[dialogId]
    }
  },

  actions: {
    openLogoutDialog() {
      this.isLogoutDialogOpen = true
    },
    closeLogoutDialog() {
      this.isLogoutDialogOpen = false
    },

    openLogoutPopup() {
      this.isLogoutPopupOpen = true
    },
    closeLogoutPopup() {
      this.isLogoutPopupOpen = false
    },

    openHelpfulInfoDialog(type: number) {
      this.isHelpfulInfoDialogOpen = true
      this.helpfulInfoDialogType = type
    },
    closeHelpfulInfoDialog() {
      this.isHelpfulInfoDialogOpen = false
    },

    openSelectPhoneDialog() {
      this.isSelectPhoneDialogOpen = true
    },
    closeSelectPhoneDialog() {
      this.isSelectPhoneDialogOpen = false
    },

    openSelectEmailDialog() {
      this.isSelectEmailDialogOpen = true
    },
    closeSelectEmailDialog() {
      this.isSelectEmailDialogOpen = false
    },

    openSelectContractDialog() {
      this.isSelectContractDialogOpen = true
    },
    closeSelectContractDialog() {
      this.isSelectContractDialogOpen = false
    },

    openContactPhoneBulkEditDialog() {
      this.isContactPhoneBulkEditDialog = true
    },
    closeContactPhoneBulkEditDialog() {
      this.isContactPhoneBulkEditDialog = false
    },

    openProfileDeletionDialog() {
      this.isProfileDeletionDialogOpen = true
    },
    closeProfileDeletionDialog() {
      this.isProfileDeletionDialogOpen = false
    },

    openNewPhoneDialog() {
      this.isNewPhoneDialogOpen = true
    },
    closeNewPhoneDialog() {
      this.isNewPhoneDialogOpen = false
    },

    openNewEmailDialog() {
      this.isNewEmailDialogOpen = true
    },
    closeNewEmailDialog() {
      this.isNewEmailDialogOpen = false
    },

    openPhonePinEditDialog() {
      this.isPhonePinDialogOpen = true
    },
    closePhonePinEditDialog() {
      this.isPhonePinDialogOpen = false
    },

    openSelectTenantDialog() {
      this.isSelectTenantDialogOpen = true
    },
    closeSelectTenantDialog() {
      this.isSelectTenantDialogOpen = false
    },

    openSenecTenantUnavailableDialog() {
      this.isSenecUnavailableDialogOpen = true
    },

    openDialog(dialogId: DialogId) {
      this.isOpen[dialogId] = true
    },
    closeDialog(dialogId: DialogId) {
      this.isOpen[dialogId] = false
    },

    setDialogHeading(heading: string) {
      this.dialogHeading = heading
    }
  }
})

export default useDialogsStore
