import type { UseFetchReturn } from '@vueuse/core'

import useFetch from '~/middleware/utils/useFetch'
import { API_URLS } from '~/middleware/utils/useFetch/types'
import { TenantId } from '~/composables/domain/useTenantConfig/types'

interface BaseApiOptions {
  abortAfterDefaultTimeout?: boolean
  tenant?: TenantId
}

export function createPaperlessCommunication(email: string, contractIds: string[], options: BaseApiOptions = { abortAfterDefaultTimeout: false, tenant: undefined }): UseFetchReturn<void> {
  const apiFetch = useFetch({ needAuthorization: true, tenant: options.tenant })

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, contractIds })
  }

  return apiFetch<void>(API_URLS.PAPERLESS_COMMUNICATION, requestOptions)
}
