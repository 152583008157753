<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppNavigationHeader'
})
</script>

<script setup lang="ts">
import { useQuasar } from 'quasar'

import TestIds from '~cypress/types/testIds'
import { Routes } from '~/routing/types'
import useDisplayLogout from '~/composables/useDisplayLogout'
import useUserStore from '~/stores/user'
import { useUserRegistrationData } from '~/composables/useUserRegistrationData'
import useUserPersonalData from '~/composables/useUserPersonalData'
import useTenantDefinedObservable from '~/composables/domain/useTenantDefinedObserver'
import { AppNavigationHeaderProps } from './types'

const props = defineProps({
  isAuthenticated: { type: Boolean, default: true },
  navTabsDisabled: { type: Boolean, default: false },
  hasBorder: { type: Boolean, default: false }
})

const $q = useQuasar()

const router = useRouter()

const isHiddenElement = ref(false)
const headerLogo = ref<HTMLElement| null>(null)

const userStore = useUserStore()
const profilePictureContent = computed(() => userStore.customerProfileData?.personalData?.avatarContent)

const handleScroll = () => {
  if (!headerLogo.value) return

  const currentCursorPosition = window.scrollY

  if (currentCursorPosition > headerLogo.value.offsetHeight) {
    isHiddenElement.value = true
  } else {
    isHiddenElement.value = false
  }
}

const { isLoadedAndTenantDefined: isUserRegistrationDataLoadedAndTenantDefinend } = useUserRegistrationData()

const handleDataFetching = async () => {
  if (!isUserRegistrationDataLoadedAndTenantDefinend.value) return

  try {
    await useUserPersonalData()
  } catch(error: unknown) {
    // error handler
  }
}

onMounted(() => {
  handleDataFetching()

  if (!props.navTabsDisabled && $q.screen.width < 855) {
    window.addEventListener('scroll', handleScroll)
  }
})

const onTenantLoaded = () => {
  handleDataFetching()
}
const { observe } = useTenantDefinedObservable()
const unobserve = observe(onTenantLoaded)

const handleProfilePictureClick = () => {
  if (!props.isAuthenticated) {
    router.push(Routes.HOME)
    return
  }

  useDisplayLogout()
}

onUnmounted(() => {
  unobserve()

  window.removeEventListener('scroll', handleScroll)
})

defineExpose({
  handleProfilePictureClick
})
</script>

<template>
  <div
    :data-testId="TestIds.APP_NAVIGATION_HEADER"
    class="app-navigation-header"
    :class="{ 'bordered': hasBorder }"
  >
    <div class="app-header-content">
      <div
        ref="headerLogo"
        class="header-logo-container"
        :class="{ 'hidden-element': isHiddenElement }"
      >
        <router-link
          :to="Routes.HOME"
          class="logo-icon cursor-pointer"
        >
          <IconLogo />
        </router-link>
      </div>

      <NavTabs
        v-if="!navTabsDisabled && isAuthenticated"
        class="header-nav"
      />

      <div
        class="header-avatar-container"
        :class="{ 'hidden-element': isHiddenElement }"
      >
        <ProfilePicture
          :data-testId="TestIds.HEADER_PROFILE_PICTURE"
          :avatar-content="profilePictureContent"
          class="header-profile-picture cursor-pointer"
          @click="handleProfilePictureClick"
          is-minified
        >
          <LogoutPopup />
        </ProfilePicture>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.header-profile-picture.minified {
  font-size: 12px;
  height: 32px;
  width: 32px;
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
@import '~vars';

.hidden-element {
  display: none;
}

.app-navigation-header {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 2;
}

.app-navigation-header.bordered {
  border-bottom: 1px solid rgba($color: $dune, $alpha: 0.15);
}

.app-header-content {
  align-items: center;
  display: flex;
}

.logo-icon {
  display: block;
  height: 32px;
}

@media (min-width: 1170px) {
  .app-header-content {
    height: 64px;
    justify-content: space-between;
    margin: 0 auto;

    padding: 0 48px;
    width: 100%;
  }

  .header-logo-container {
    order: 1;
    margin: 16px 0;
  }

  .header-nav {
    order: 2;
    height: 100%;
  }

  .header-avatar-container {
    order: 3;
    margin: 16px 0;
  }
}

@media (max-width: 1169px) {
  .app-header-content {
    flex-wrap: wrap;
  }

  .header-logo-container {
    order: 1;
    height: 56px;
    width: 50%;

    .logo-icon {
      float: left;
      margin: 12px 0 12px 16px;

      @media (min-width: 650px) {
        margin-left: 24px;
      }
    }
  }

  .header-avatar-container {
    order: 2;
    width: 50%;
    height: 56px;

    .header-profile-picture {
      float: right;
      margin: 12px 12px 12px 0;

      @media (min-width: 650px) {
        margin-right: 24px;
      }
    }
  }

  .header-nav {
    order: 3;
    width: 100%;

    @media (min-width: 855px) and (max-width: 1169px) {
      width:fit-content;
      margin: 0 auto;
    }
  }
}
</style>

<style lang="scss">
@media (min-width: 650px) and (max-width: 854px) {
  .app-header-content .header-nav .e-tabs .q-tab {
    margin: 0 16px;
  }
}
</style>
