import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'

import { type UserModule } from '~/types'
import externalApisTelemetryInitializer from './telemetry-initializers/external-apis'

export const install: UserModule = ({ app }) => {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: app?.config?.globalProperties?.$deployConfig?.APPLICATIONINSIGHTS_CONNECTION_STRING || '',
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableUnhandledPromiseRejectionTracking: true,
      disablePageUnloadEvents: ['beforeunload', 'unload'],
      correlationHeaderExcludedDomains: ['*qas-login.enbw.com', '*pro-login.enbw.com','*myenergykey.com','cdn.cookielaw.org']
    }
  })

  if (!appInsights || !app?.config) {
    return
  }

  app.provide('appInsights', appInsights)

  appInsights.loadAppInsights()

  // register custom telemetry initializers
  const customTelemetryInitializers: Array<(envelope: ITelemetryItem) => boolean> = [
    externalApisTelemetryInitializer
  ]
  for (const initializer of customTelemetryInitializers) {
    appInsights.addTelemetryInitializer(initializer)
  }

  appInsights.trackPageView()

  app.config.errorHandler = (error) => {
    appInsights.trackException({
      exception: error as Error | undefined
    })
  }
}
