export enum ESkeletonTypes {
  TEXT = 'text',
  RECT = 'rect',
  CIRCLE = 'circle',
  BUTTON = 'QBtn',
  BADGE = 'QBadge',
  CHIP = 'QChip',
  TOOLBAR = 'QToolbar',
  CHECKBOX = 'QCheckbox',
  RADIO = 'QRadio',
  TOGGLE = 'QToggle',
  SLIDER = 'QSlider',
  RANGE = 'QRange',
  AVATAR = 'QAvatar',
  INPUT = 'QInput'
}

export interface ESkeletonProps {
  type: ESkeletonTypes
  size?: string
  width?: string
  height?: string
}
