import { ContractDataItem } from '~/stores/user/types'

function getProductName(contract: ContractDataItem): string
{
  const hasComputedProductName = !contract.productName && Boolean(contract.businessArea)
  const businessAreaName = contract.businessArea?.toLocaleLowerCase() ?? ''
  const computedBusinessAreaName = businessAreaName == 'energy' ? 'Energie' : businessAreaName

  return hasComputedProductName
      ? `${computedBusinessAreaName}-Vertrag`
      : contract.productName
}

export function toProductName(contract: ContractDataItem) : ContractDataItem {
  return {
    ...contract,
    productName: getProductName(contract)
  }
}
