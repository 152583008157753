import type { Locale } from 'vue-i18n'
import { createI18n } from 'vue-i18n'
import type { UserModule } from '~/types'

import en from '../../../locales/en.json'
import de from '../../../locales/de.json'

// Import i18n resources
// https://vitejs.dev/guide/features.html#glob-import
//
// Don't need this? Try vitesse-lite: https://github.com/antfu/vitesse-lite
export const defaultLocale = 'de'

export const messages = Object.fromEntries(
  Object.entries(import.meta.glob<{ default: any }>('../../locales/*.json', { eager: true } ))
    .map(([path, loadLocale]) => [path.match(/([\w-]*)\.json$/)?.[1], loadLocale.default])
) as Record<Locale, { default: Record<string, string> }>

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  messages: {
    en,
    de
  }
})

export const install: UserModule = ({ app }) => {
  app?.use(i18n)
}
