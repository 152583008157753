enum DialogsTestIds {
  ACTIVE_DIALOG_CLOSE_ICON = 'activeDialogCloseIcon',
  ACTIVE_DIALOG_CLOSE_BACK_ICON = 'activeDialogCloseBackIcon',

  HELPFUL_INFO_DIALOG = 'helpfulInfoDialog',
  HELPFUL_INFO_PIN = 'helpfulInfoPin',
  HELPFUL_INFO_PIN_VIEW_HIDE_BUTTON = 'helpfulInfoPinViewHideButton',
  HELPFUL_INFO_DIALOG_CLOSE = 'helpfulInfoDialogClose',

  SELECT_PHONE_DIALOG = 'selectPhoneDialog',
  SELECT_PHONE_DIALOG_CTA = 'selectPhoneDialogCta',
  SELECT_PHONE_DIALOG_NEW_PHONE_CTA = 'selectPhoneDialogNewPhoneCta',

  SELECT_CONTRACT_DIALOG = 'selectContractDialog',
  SELECT_CONTRACT_DIALOG_CTA = 'selectContractDialogCta',

  CONTACT_PHONE_BULK_EDIT_DIALOG = 'contactPhoneBulkEditDialog',
  CONTACT_PHONE_BULK_EDIT_DIALOG_CTA = 'contactPhoneBulkEditDialogCta',
  CONTACT_PHONE_BULK_EDIT_DIALOG_CLOSE = 'contactPhoneBulkEditDialogClose',

  NEW_PHONE_DIALOG = 'newPhoneDialog',
  NEW_PHONE_DIALOG_COUNTRY_DROPDOWN = 'newPhoneDialogCountryDropdown',
  NEW_PHONE_DIALOG_EDIT_INPUT = 'newPhoneDialogEditInput',
  NEW_PHONE_DIALOG_EDIT_SUBMIT_BUTTON = 'newPhoneDialogSubmitButton',

  NEW_EMAIL_DIALOG = 'newEmailDialog',

  SELECT_EMAIL_DIALOG = 'selectEmailDialog',
  SELECT_EMAIL_DIALOG_NEW_EMAIL_CTA = 'selectEmailDialogNewEmailCta',
  SELECT_EMAIL_DIALOG_EXISTING_EMAIL_CTA = 'selectEmailDialogExistingEmailCta',

  EMAIL_FORM_SUBMIT = 'emailFormSubmit',
  EMAIL_SENT_CONTENT = 'emailSentContent',

  EDIT_BIRTHDATE_DIALOG = 'editBirthdateDialog',
  BIRTHDATE_FORM_SUBMIT = 'birthdateFormSubmit',
}

export default DialogsTestIds
