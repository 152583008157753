import { type UserModule } from '~/types'

import useAuth from '~/middleware/utils/useAuth'

export const install: UserModule = ({ app }) => {
  const deployConfig = app?.config?.globalProperties?.$deployConfig || {}

  if (!deployConfig.AUTH0_DOMAIN || !deployConfig.AUTH0_CLIENT_ID) {
    return
  }

  app.use(
    useAuth(deployConfig)
  )
}
