<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaperlessCommunicationDialogContent'
})
</script>

<script setup lang="ts">
import { PaperlessCommunicationDialogContentProps } from './types'
import TestIds from '~cypress/types/testIds'

import EDialogContentTitle from '~/components/EDialogContentTitle/index.vue'

const { t } = useI18n()

const emit = defineEmits(['close', 'confirm', 'skip'])

const props = defineProps({
  mobile: { type: Boolean },
  maximized: { type: Boolean },
  title: null,
  text: null,
  confirmActionText: null,
  isSaving: { type: Boolean }
})

/**
 * Takes into account number of CTAs in actions container.
 */
const contentBottomPadding = computed(() => {
  const defaultContentBottomPadding = undefined

  if (props.mobile) {
    return defaultContentBottomPadding
  }

  const btnHeight = 49.5
  const btnGap = 16
  const outerSpacing = 24 + 56
  const actionBtnCount = 2

  const padding = actionBtnCount * btnHeight + btnGap + outerSpacing
  return `${padding}px`
})

const contentStyle = computed(() => {
  const paddingStyle = { 'padding-bottom': contentBottomPadding.value }

  return { ...paddingStyle }
})

defineExpose({
  TestIds,

  contentStyle
})
</script>

<template>
  <div
    id="paperless-communication-dialog-content"
    class="full-height paperless-communication-dialog-content"
  >
    <q-card-section :style="contentStyle">
      <EDialogContentTitle
        id="paperless-communication-dialog-content-title"
        dialog-content-selector="#paperless-communication-dialog-content"
        :title="title"
        :animated="!mobile"
        :mobile="mobile"
        class="q-mb-lg"
      />

      <div class="paperless-communication-dialog-content__illustration full-width column justify-center">
        <img
          src="/assets/images/paperless-communication.svg"
          :height="mobile ? 160 : 210"
          alt="illustration of a letter with a paper inside"
        >
      </div>

      <p
        class="p1 q-mb-md"
        v-html="text"
      />
    </q-card-section>

    <q-card-actions>
      <EButton
        :data-testId="TestIds.PAPERLESS_COMMUNICATION_DIALOG_CONFIRM_CTA"
        color="primary-blue"
        :text="confirmActionText"
        full-width
        :is-loading="isSaving"
        class="q-mb-md"
        @click="emit('confirm')"
      />

      <TextButton
        :data-testId="TestIds.PAPERLESS_COMMUNICATION_DIALOG_SKIP_CTA"
        :button-text="t('dialogs.paperless-communication.actions.skip')"
        :on-button-click="() => emit('skip')"
        class="full-width"
      />
    </q-card-actions>
  </div>
</template>

<style lang="scss" scoped>
@import '~vars';

.paperless-communication-dialog-content {
  .q-card__section {
    h3, .t5 {
      margin-bottom: 24px;
    }

    p {
      color: $tapa;
    }
  }

  &__illustration {
    margin-bottom: 32px;
  }

  .q-card__actions {
    padding-bottom: 24px;
  }

  @media (min-width: 699px) {
    &__illustration {
      margin-top: -16px;
      margin-bottom: 24px;
    }
  }
}
</style>
