<script setup lang="ts">
import { useDialog } from '~/composables/dialogs/useDialog'
import { DialogId } from '~/composables/dialogs/useDialog/types'

import { useUserRegistrationData } from '~/composables/useUserRegistrationData'
import useTenantDefinedObservable from '~/composables/domain/useTenantDefinedObserver'
import { useUserContractData } from '../../composables/useUserContractData'
import { Cache } from '~/composables/useUserPersonalData/types'
import { usePaperlessCommunication } from '~/composables/usePaperlessCommunication'
import useToastNotifications from '~/composables/useToastNotifications'

import PaperlessCommunicationDialog from '~/components/paperless-communication/PaperlessCommunicationDialog.vue'

import type { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { TRACKING_EVENTS } from '~/utils/constants/eventNames'

const { t } = useI18n()
const appInsights = inject('appInsights') as ApplicationInsights

const { isLoadedAndTenantDefined: isUserRegistrationDataLoadedAndTenantDefinend } = useUserRegistrationData()
const { open: openPaperlessCommunicationDialog } = useDialog(DialogId.PAPERLESS_COMMUNICATION_DIALOG)
const { fetch: fetchUserContractData } = useUserContractData()

const {
  detectContractsWithPaperCommunication,
  isFirstChoiceSkip: isPaperlessCommunicationFirstChoiceSkip,
  getNotificationPaperKept, getNotificationFailed, getNotificationOptedIn
} = usePaperlessCommunication(useUserContractData, t)

async function startPaperlessCommunicationDialogIfNecessary() {
  if (!isUserRegistrationDataLoadedAndTenantDefinend.value) return

  try {
    await fetchUserContractData()
  } catch (_error: unknown) {
    // error handler
  } finally {
    if (detectContractsWithPaperCommunication() && !isPaperlessCommunicationFirstChoiceSkip.value) {
      openPaperlessCommunicationDialog()
    }
  }
}

onMounted(() => {
  startPaperlessCommunicationDialogIfNecessary()
})

const onTenantLoaded = () => {
  startPaperlessCommunicationDialogIfNecessary()
}

const { observe } = useTenantDefinedObservable()
const unobserve = observe(onTenantLoaded)

const { notify } = useToastNotifications()

function notifyPaperlessCommunicationChosen() {
  notify(getNotificationOptedIn(), true)
}

function handlePaperlessCommunicationChosen() {
  notifyPaperlessCommunicationChosen()

  appInsights.trackEvent({
    name: TRACKING_EVENTS.PAPERLESS_CONTRACT,
    properties: {
      isSucceeded: true
    }
  })

  // reload contract data
  fetchUserContractData(Cache.Disabled)
}

function notifyPaperlessCommunicationFailed() {
  notify(getNotificationFailed(), true)

  appInsights.trackEvent({
    name: TRACKING_EVENTS.PAPERLESS_CONTRACT,
    properties: {
      isSucceeded: false
    }
  })
}

function notifyPaperCommunicationKept() {
  notify(getNotificationPaperKept(), true)
}

onBeforeUnmount(() => {
  unobserve()
})

defineExpose({
  handlePaperlessCommunicationChosen,
  notifyPaperlessCommunicationFailed,
  notifyPaperCommunicationKept
})
</script>

<template>
  <PaperlessCommunicationDialog
    @chosen="handlePaperlessCommunicationChosen"
    @failed="notifyPaperlessCommunicationFailed"
    @paper-kept="notifyPaperCommunicationKept"
  />
</template>
