import type { ComputedRef } from 'vue'

import useUserPersonalData, { INITIAL_CUSTOMER_PROFILE } from '~/composables/useUserPersonalData'
import useUserStore from '~/stores/user'
import { CommunicationChannelData, ContactEmailAddress, ContactPhoneNumber, ContractEmailAddress, ContractPhoneNumber } from '~/stores/user/types'
import { Cache, UserPersonalDataComposable } from '~/composables/useUserPersonalData/types'

export interface UserCommunicationChannelDataComposable extends UserPersonalDataComposable<CommunicationChannelData> {
  communicationChannelData: ComputedRef<CommunicationChannelData>

  contractEmailAddresses: ComputedRef<ContractEmailAddress[]>
  contactEmailAddresses: ComputedRef<ContactEmailAddress[]>
  contactEmailAddress: ComputedRef<ContactEmailAddress | undefined>
  emailCommunicationChannels: ComputedRef<Array<ContractEmailAddress | ContactEmailAddress>>
  hasEmailCommunication: ComputedRef<boolean>

  contactPhoneNumbers: ComputedRef<ContactPhoneNumber[]>
  contactPhoneNumber: ComputedRef<ContactPhoneNumber | undefined>
  contractPhoneNumbers: ComputedRef<ContractPhoneNumber[]>
  isPhoneNumberKnown: (phoneId: string) => boolean
  hasPhoneNumbers: ComputedRef<boolean>
}

export const useUserCommunicationChannelData = (): UserCommunicationChannelDataComposable => {
  const userStore = useUserStore()

  const fetch = async (cacheState?: Cache): Promise<CommunicationChannelData> => {
    const data = await useUserPersonalData(cacheState)

    return data.communicationChannelData
  }

  const communicationChannelData = computed<CommunicationChannelData>(() =>
    userStore.customerProfileData?.communicationChannelData ?? INITIAL_CUSTOMER_PROFILE.communicationChannelData
  )

  const contractEmailAddresses = computed<ContractEmailAddress[]>(() => communicationChannelData.value.contractEmailAddresses ?? [])

  const contactEmailAddresses = computed<ContactEmailAddress[]>(() => communicationChannelData.value.contactEmailAddresses ?? [])

  /**
   * Returns first from collection of customer's contact addresses
   *
   * @returns {ComputedRef<ContactEmailAddress | undefined>}
   */
  const contactEmailAddress = computed<ContactEmailAddress | undefined>(() => contactEmailAddresses.value?.[0])

  const emailCommunicationChannels: ComputedRef<Array<ContractEmailAddress | ContactEmailAddress>> = computed(() => [...contractEmailAddresses.value, ...contactEmailAddresses.value])

  const hasEmailCommunication = computed(() => communicationChannelData.value.emailCount > 0)

  const contactPhoneNumbers = computed<ContactPhoneNumber[]>(() =>
      communicationChannelData.value.contactPhoneNumbers || []
    )

  /**
   * Returns first from collection of customer's contact phone numbers
   *
   * @returns {ComputedRef<ContactPhoneNumber | undefined>}
   */
  const contactPhoneNumber = computed(() => contactPhoneNumbers.value?.[0])

  const contractPhoneNumbers = computed<ContractPhoneNumber[]>(() =>
      communicationChannelData.value.contractPhoneNumbers || []
    )

  const hasPhoneNumbers = computed(() => communicationChannelData.value.phoneNumberCount > 0)

  /**
   * Makes a lookup and returns true if given phone id exists in contact
   * or contract phone numbers collection
   *
   * @returns {boolean}
   */
  const isPhoneNumberKnown = (phoneId: string) => {
    const isKnownContactPhoneNumber = contactPhoneNumbers.value?.find((phoneNumber) => phoneNumber.id === phoneId)
    const isKnownContractPhoneNumber = contractPhoneNumbers.value?.find((phoneNumber) => phoneNumber.id === phoneId)

    return !!isKnownContactPhoneNumber || !!isKnownContractPhoneNumber
  }

  return {
    fetch,
    isLoading: computed(() => userStore.isCustomerProfileDataLoading),
    communicationChannelData,

    contractEmailAddresses,
    contactEmailAddresses,
    contactEmailAddress,
    emailCommunicationChannels,
    hasEmailCommunication,

    contactPhoneNumbers,
    contactPhoneNumber,
    contractPhoneNumbers,
    isPhoneNumberKnown,
    hasPhoneNumbers
  }
}
