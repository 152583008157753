export enum CypressLocalStorage {
  AUTH0 = 'auth0Cypress',
  PERSONA = 'persona'
}

export interface CypressEnv {
  auth_url: string
  auth_username: string
  auth_password: string
  auth_username_enbw: string
  auth_password_enbw: string
  auth_audience: string
  auth_client_id: string
  auth_scope: string
  environment: string
  subscriptionKey: string
  defaultCommandTimeout: number
  bff_url: string
  intercept_url: string
}

export interface CypressConfig {
  chromeWebSecurity: boolean
  baseUrl: string
  projectId: string
  video: boolean
  supportFile: string
  specPattern: string
}

export enum TenantId {
  Yello = '200',
  Enbw = '100'
}
