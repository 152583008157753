<script setup lang="ts">
import { useQuasar } from 'quasar'

import { DIALOG_BREAKPOINT, useDialog } from '~/composables/dialogs/useDialog'
import { DialogId } from '~/composables/dialogs/useDialog/types'
import { useUserRegistrationData } from '~/composables/useUserRegistrationData'

import { useUserCommunicationChannelData } from '~/composables/useUserCommunicationChannelData'
import { useUserContractData } from '~/composables/useUserContractData'
import { usePaperlessCommunication } from '~/composables/usePaperlessCommunication'
import TestIds from '~cypress/types/testIds'

import EDialog from '~/components/design-system/EDialog/index.vue'
import PaperlessCommunicationDialogContent from './PaperlessCommunicationDialogContent.vue'
import EmailCommunicationChannelsDialog from './EmailCommunicationChannelsDialog.vue'
import { ContactEmailAddress, ContractEmailAddress } from '~/stores/user/types'

const emit = defineEmits(['chosen', 'paper-kept', 'failed'])

const { t } = useI18n()

const $q = useQuasar()
const isDialogMobile = computed(() => $q.screen.width <= DIALOG_BREAKPOINT)

const { isOpen, close } = useDialog(DialogId.PAPERLESS_COMMUNICATION_DIALOG)

const { registrationData } = useUserRegistrationData()
const { emailCommunicationChannels } = useUserCommunicationChannelData()

const {
  optIn: optInForPaperlessCommunication,
  rememberFirstChoiceSkip
} = usePaperlessCommunication(useUserContractData, t)

const isSingleEmailOption = computed(() => {
  const emailCommunicationChannelsWithoutRegistrationEmail = emailCommunicationChannels.value?.filter(
    (address: ContractEmailAddress | ContactEmailAddress) => address.value !== registrationData.value?.email
  )

  return !emailCommunicationChannelsWithoutRegistrationEmail?.length
})

const formattedText = computed(() => isSingleEmailOption.value
  ? t('dialogs.paperless-communication.text', [`an: <strong class="t5">${registrationData.value?.email}</strong>`])
  : t('dialogs.paperless-communication.text', ['']))

const primaryActionText = computed(() => isSingleEmailOption.value
  ? t('dialogs.paperless-communication.actions.confirm-single')
  : t('dialogs.paperless-communication.actions.confirm'))

const dialogCloseHandler = async () => {
  rememberFirstChoiceSkip()

  close()
}

const {
  open: openEmailCommunicationChannelsDialog,
  close: closeEmailCommunicationChannelsDialog
} = useDialog(DialogId.EMAIL_COMMUNICATION_CHANNELS_DIALOG)

const isSaving = ref(false)

function confirmPaperlessCommunicationWithEmail(email: string, isSelectedFromMultiple?: boolean) {
  isSaving.value = true
  const { onFetchResponse, onFetchError, onFetchFinally } = optInForPaperlessCommunication(email)

  onFetchResponse(() => {
    emit('chosen')

    if (isSelectedFromMultiple) {
      closeEmailCommunicationChannelsDialog()
    } else {
      close()
    }
  })

  onFetchError(() => {
    emit('failed')
  })

  onFetchFinally(() => {
    isSaving.value = false
  })
}

function handlePaperlessCommunicationConfirm() {
  if (isSingleEmailOption.value) {
    confirmPaperlessCommunicationWithEmail(registrationData.value?.email as string)

    return
  }

  close()
  openEmailCommunicationChannelsDialog()
}
function handlePaperlessCommunicationSkip() {
  rememberFirstChoiceSkip()

  close()
  emit('paper-kept')
}

const emailCommunicationChannelsDialogTitle = computed(() => isSingleEmailOption.value
  ? t('dialogs.email-communication-channels.title-single-option')
  : t('dialogs.email-communication-channels.title')
)

function handleSelectedEmailCommunicationChannel(email: string) {
  confirmPaperlessCommunicationWithEmail(email, true)
}
function handleEmailCommunicationChannelsDialogClose() {
  rememberFirstChoiceSkip()

  closeEmailCommunicationChannelsDialog()
}

defineExpose({
  t,
  TestIds,

  isOpen,
  close,

  isSingleEmailOption,
  formattedText,
  primaryActionText,
  emailCommunicationChannelsDialogTitle,

  handlePaperlessCommunicationConfirm,
  isSaving,
  handlePaperlessCommunicationSkip
})
</script>

<template>
  <!-- eslint-disable vue/no-multiple-template-root -->
  <EDialog
    id="paperless-communication-dialog"
    :model-value="isOpen"
    :data-testId="TestIds.PAPERLESS_COMMUNICATION_DIALOG"
    desktop-width="650px"
    @close="dialogCloseHandler"
    :scroll-event="!isDialogMobile"
  >
    <template #default="{ isMobile, isMaximized }">
      <PaperlessCommunicationDialogContent
        :title="t('dialogs.paperless-communication.title')"
        :text="formattedText"
        :mobile="isMobile"
        :maximized="isMaximized"
        :is-saving="isSaving"
        :confirm-action-text="primaryActionText"
        @confirm="handlePaperlessCommunicationConfirm"
        @skip="handlePaperlessCommunicationSkip"
      />
    </template>
  </EDialog>

  <EmailCommunicationChannelsDialog
    :title="emailCommunicationChannelsDialogTitle"
    :single-option="isSingleEmailOption"
    :primary-action-title="t('dialogs.email-communication-channels.actions.select')"
    :is-processing="isSaving"
    @select="handleSelectedEmailCommunicationChannel"
    @close="handleEmailCommunicationChannelsDialogClose"
  />
</template>

<style lang="scss">
#paperless-communication-dialog {
  .q-dialog__inner--standard > div {
    @media (min-width: 699px) {
      height: 747px;
    }
  }
}
</style>
