import type { ComputedRef } from 'vue'
import type { LocationQuery } from 'vue-router'

import useAppConfig from '~/composables/useAppConfig'
import useAppStore from '~/stores/app'
import { extractSiteName, getReferrerBackURL, detectReferrerURL } from '~/utils/referrerURL'

export interface ReferrerComposable {
  referrerURL: ComputedRef<string>;
  referrerName: ComputedRef<string>;
  computeReferrerBackURL: (toastType: string | undefined) => string;
  detectReferrerAndSaveToStore: (query : LocationQuery) => void;
}

const useReferrer = (): ReferrerComposable => {
  const appStore = useAppStore()
  const appConfig = useAppConfig()

  const referrerURL = computed(() => appStore.referrerURL)
  const referrerName = computed(() => extractSiteName(appStore.referrerURL, appConfig.REFERRING_TOUCHPOINTS))

  const computeReferrerBackURL = (toastType: string | undefined) => getReferrerBackURL (appStore.referrerURL, toastType)

  const detectReferrerAndSaveToStore = (query : LocationQuery) =>{
    const referrerURL = detectReferrerURL(query, appConfig.REFERRING_TOUCHPOINTS)
    appStore.setReferrerURL(referrerURL)
  }

  return {
    referrerURL,
    referrerName,
    computeReferrerBackURL,
    detectReferrerAndSaveToStore
  }
}

export default useReferrer
