import type { ITelemetryItem } from '@microsoft/applicationinsights-web'

// external APIs to exclude
const EXTERNAL_API_PATTERN_MATCHERS = [
  /translate-pa\.googleapis\.com/
]

export default function(envelope: ITelemetryItem): boolean {
  const target = envelope.baseData?.target
  if (target) {
    return EXTERNAL_API_PATTERN_MATCHERS.every((matcher) => !matcher.test(target))
  }

  return true
}
