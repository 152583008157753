export enum Routes {
  HOME = '/',
  OVERVIEW = '/overview',
  PERSONAL_DATA = '/personal-data',
  CONTRACTS_PAYMENTS = '/contracts-payments',
  SECURITY = '/security',
  RESET_PASSWORD = '/security/password-reset',
  RESET_EMAIL = '/security/email-reset',
  RESET_PIN = '/security/pin-reset',
  RESET_PASSWORD_EMAIL = '/security/password-reset/email',
  PRIVACY = '/privacy',

  DATA_PROTECTION = '/data-protection',
  LOGIN = '/login',
  IDENTITY_LINKING = '/code',

  ADDRESS_LIST = '/personal-data/address-list',
  ADDRESS_LIST_DELIVERY = '/personal-data/address-list/delivery',
  ADDRESS_LIST_BILLING = '/personal-data/address-list/billing',
  ADDRESS_LIST_CONTACT = '/personal-data/address-list/contact',

  EMAIL_LIST = '/personal-data/email-list',
  EMAIL_LIST_CONTRACT = '/personal-data/email-list/contract',
  EMAIL_LIST_CONTACT = '/personal-data/email-list/contact',
  PERSONAL_DATA_RESET_EMAIL = '/personal-data/email-list/email-reset',

  CONTRACTS_PAYMENTS_CONTRACT = '/contracts-payments/contract',

  PAYMENT_LIST = '/contracts-payments/payment-list',
  PAYMENT_LIST_CONTRACT_BANK_ACCOUNT = '/contracts-payments/payment-list/bank-account',
  PAYMENT_LIST_SELF_BANK_ACCOUNT  = '/contracts-payments/payment-list/self-bank-account',
  SELF_PAYMENT = '/contracts-payments/payment-list/self-payment/self-payment',

  PHONE_LIST = '/personal-data/phone-list',
  PHONE_LIST_CONTRACT = '/personal-data/phone-list/contract',
  PHONE_LIST_CONTACT = '/personal-data/phone-list/contact',

  PRIVACY_DELETE_MEK = '/privacy/delete-account',
  PRIVACY_DATA_VISUALIZATION = '/privacy/data-visualization',

  API_UNAVAILABLE = '/503'
}

export enum RouteNames {
  HOME = 'home',
  OVERVIEW = 'overview',
  DATA_PROTECTION = 'data-protection',

  SECURITY = 'security',
  EMAIL_RESET = 'email-reset',
  PIN_RESET = 'pin-reset',
  PASSWORD_RESET = 'password-reset',
  PASSWORD_RESET_EMAIL = 'password-reset-email',

  PRIVACY = 'privacy',
  DELETE_ACCOUNT = 'delete-account',

  PERSONAL_DATA = 'personal-data',

  CONTRACTS_PAYMENTS = 'contracts-payments',
  CONTRACT_ITEM_DETAIL = 'contract-item-detail',

  ADDRESS_LIST = 'address-list',
  ADDRESS_TYPE_LIST = 'address-type-list',
  ADDRESS_ITEM_DETAIL = 'address-item-detail',

  EMAIL_LIST = 'email-list',
  EMAIL_LIST_RESET = 'email-list-reset',
  EMAIL_TYPE_LIST = 'email-type-list',
  EMAIL_ITEM_DETAIL = 'email-item-detail',

  PAYMENT_LIST = 'payment-list',
  PAYMENT_TYPE_LIST = 'payment-type-list',
  PAYMENT_ITEM_DETAIL = 'payment-item-detail',

  PHONE_LIST = 'phone-list',
  PHONE_TYPE_LIST = 'phone-type-list',
  PHONE_ITEM_DETAIL = 'phone-item-detail',

  NOT_FOUND = 'all'
}

export enum ExternalRoutes {
  EDIT_EMAIL = '/goto/E5B755A764DA6ACC03E565C311F379D5',
  EDIT_PHONE = '/goto/7284B5C7AED6F9D822BB17F60B0683CB',
  EDIT_DELIVERY_ADDRESS = '/goto/07B7EFCCC10441568EEECB2B9E02755C',
  EDIT_CONTRACT_ADDRESS = '/goto/732F9F92C144C2E87290DA88EB787889',
  EDIT_BANK_ACCOUNT = '/goto/9FD04CFECB082160CCEDB9A71FBA1E3F',
  EDIT_BANK_ACCOUNT_LINK = '/bankverbindung/bearbeiten',
  EDIT_PIN = '/goto/3B7DC8BF9DEB57DF1FDA71101663E8C4',
  QUICK_SIGN_IN_PARAM = 'quick-sign-in=true',
  ADVERTISING_PERMISSION = '/goto/153325440FC7AE6778BD1EFA12FD3880',
  PROFILE_PERMISSION = '/goto/577CABF51161D6C027C7994B9196C152',
  DATA_VISUALIZATION_PERMISSION = '/goto/EC5C85145C14A65394326D25469D3840',

  USER_DATA_PAGE = '/nutzerdaten',

  MOBILITY_APP_ANDROID = 'https://play.google.com/store/apps/details?id=com.enbw.ev',
  MOBILITY_APP_IOS = 'https://apps.apple.com/de/app/enbw-mobility/id1232210521',
  MOBILITY_APP_DESKTOP = 'https://www.enbw.com/elektromobilitaet/produkte/mobilityplus-app',

  HOUSE_PLUS_APP_IOS = 'https://apps.apple.com/de/app/enbw-zuhause/id1540580646',
  HOUSE_PLUS_APP_ANDROID = 'https://play.google.com/store/apps/details?id=de.yellostrom.zuhauseplus',
  HOUSE_PLUS_APP_DESKTOP = 'https://www.enbw.com/service/app-zuhauseplus/',

  ENBW = 'https://www.enbw.com',
  ENBW_PORTAL = 'https://meine.enbw.com/login',
  ENBW_IMPRESSUM = 'https://www.enbw.com/impressum',
  ENBW_MEK = 'https://www.enbw.com/myenergykey',
  ENBW_CANCELLATION = 'https://www.enbw.com/kuendigen',

  YELLO_APP_ANDROID = 'https://play.google.com/store/apps/details?id=de.yellostrom.incontrol',
  YELLO_APP_IOS = 'https://apps.apple.com/de/app/yello-app-dein-energie-check/id1019478181',
  YELLO_APP_DESKTOP = 'https://www.yello.de/service/yello-app',
  YELLO_CANCELLATION = 'https://www.yello.de/kuendigen',

  GDPR_SCC = 'https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc',
  GDPR = 'https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en',
}
