<script setup lang="ts">
useHead({
  title: 'myEnergyKey-Konto',
  meta: [
    { name: 'description', content: 'myEnergyKey' }
  ]
})
</script>

<template>
  <RouterView />
</template>
