enum PersonalDataTestIds {
  PERSONAL_DATA_CONTENT = 'personalDataContent',
  LOGOUT_DIALOG_NAME = 'logoutDialogName',
  HEADER_NAME = 'headerName',

  PERSONAL_DATA_NAME = 'personalDataName',
  PERSONAL_DATA_BIRTHDAY = 'personalDataBirthday',
  PERSONAL_DATA_EMAIL = 'personalDataEmail',

  PERSONAL_DATA_EMAIL_PERMISSION_BTN = 'personalDataEmailPermissionBtn',
  PERSONAL_DATA_REGISTRATION_EMAIL_CARD = 'personalDataRegistrationEmailCard',
  PERSONAL_DATA_CONTACT_EMAIL_CARD = 'personalDataContractEmailCard',
  PERSONAL_DATA_CONTRACT_EMAIL_CARD = 'personalDataContactEmailCard',
  PERSONAL_DATA_EMAIL_ADV_PERMISSIONS_SUCCESS_TOAST = 'personalDataEmailAdvPermissionsSuccessToast',
  PERSONAL_DATA_EMAIL_ADV_PERMISSIONS_ERROR_TOAST = 'personalDataEmailAdvPermissionsErrorToast',
  PERSONAL_DATA_EMAIL_ECO_PERMISSIONS_SUCCESS_TOAST = 'personalDataEmailEcoPermissionsSuccessToast',
  PERSONAL_DATA_EMAIL_ECO_PERMISSIONS_ERROR_TOAST = 'personalDataEmailEcoPermissionsErrorToast',

  PERSONAL_DATA_PHONE = 'personalDataPhone',
  PERSONAL_DATA_PHONE_PERMISSION_BTN = 'personalDataPhonePermissionBtn',
  PERSONAL_DATA_EDIT_PHONE_SUCCESS_TOAST = 'personalDataEditPhoneSuccessToast',
  PERSONAL_DATA_EDIT_PHONE_ERROR_TOAST = 'personalDataEditPhoneErrorToast',
  PERSONAL_DATA_EDIT_EMAIL_SUCCESS_TOAST = 'personalDataEditEmailSuccessToast',
  PERSONAL_DATA_EDIT_EMAIL_ERROR_TOAST = 'personalDataEditEmailErrorToast',

  PERSONAL_DATA_EDIT_BIRTHDATE_SUCCESS_TOAST = 'personalDataEditBirthdateSuccessToast',
  PERSONAL_DATA_EDIT_BIRTHDATE_ERROR_TOAST = 'personalDataEditBirthdateErrorToast',

  PERSONAL_DATA_CONTACT_PHONE_CARD = 'personalDataContactPhoneCard',
  PERSONAL_DATA_CONTRACT_PHONE_CARD = 'personalDataContractPhoneCard',
  PERSONAL_DATA_PHONE_ADV_PERMISSIONS_SUCCESS_TOAST = 'personalDataPhoneAdvPermissionsSuccessToast',
  PERSONAL_DATA_PHONE_ADV_PERMISSIONS_ERROR_TOAST = 'personalDataPhoneAdvPermissionsErrorToast',
  PERSONAL_DATA_PHONE_ECO_PERMISSIONS_SUCCESS_TOAST = 'personalDataPhoneEcoPermissionsSuccessToast',
  PERSONAL_DATA_PHONE_ECO_PERMISSIONS_ERROR_TOAST = 'personalDataPhoneEcoPermissionsErrorToast',

  PERSONAL_DATA_ADDRESS = 'personalDataAddress',
  PERSONAL_DATA_CONTACT_ADDRESS_CARD = 'personalDataContactAddressCard',
  PERSONAL_DATA_DELIVERY_ADDRESS_CARD = 'personalDataDeliveryAddressCard',
  PERSONAL_DATA_BILLING_ADDRESS_CARD = 'personalDataBillingAddressCard',

  PERSONAL_DATA_EDIT_LINK = 'personalDataEditLink',
  PERSONAL_DATA_EDIT_BUTTON = 'personalDataEditButton',
  PERSONAL_DATA_EDIT_BUTTON_CONTAINER = 'personalDataEditButtonContainer',
}

export default PersonalDataTestIds
