export enum DialogId {
  ECO_PERMISSIONS_EDIT_DIALOG = 'ecoPermissionsEditDialog',
  ECO_PERMISSIONS_NESTED_DIALOG = 'ecoPermissionsNestedDialog',
  ADV_PERMISSIONS_EDIT_DIALOG = 'advPermissionsEditDialog',
  TRACKING_PERMISSIONS_EDIT_DIALOG = 'trackingPermissionsEditDialog',
  PERMISSIONS_PRODUCT_REVOCATION_DIALOG = 'permissionsProductRevocationDialog',
  PERMISSIONS_GOOGLE_CUSTOMER_MATCH_INFO_DIALOG = 'permissionsGoogleCustomerMatchInfoDialog',
  PARTNER_INFORMATION_DIALOG = 'partnerInformationDialog',
  INTEREST_PROFILE_DIALOG = 'interestProfileDialog',
  REVOCATION_INFORMATION_DIALOG = 'revocationInformationDialog',

  PAPERLESS_COMMUNICATION_DIALOG = 'paperlessCommunicationDialog',
  EMAIL_COMMUNICATION_CHANNELS_DIALOG = 'emailCommunicationChannelsDialog',

  IDENTITY_LINKING_ZIP_CODE_VALIDATION_DIALOG = 'identityLinkingZipCodeValidationDialog',
  IDENTITY_LINKING_LETTER_RENEWAL_OPTIONS_DIALOG = 'identityLinkingLetterRenewalOptionsDialog',
  IDENTITY_LINKING_EMAIL_RENEWAL_FORM_DIALOG = 'identityLinkingEmailRenewalFormDialog',
  IDENTITY_LINKING_SERVICE_CONTACT_DIALOG = 'identityLinkingServiceContactDialog',

  EDIT_BIRTHDATE_DIALOG = 'editBirthdateDialog',
}
