import type { ComputedRef } from 'vue'

export interface VisualViewportHandlerComposable {
  offsetTop: ComputedRef<number>
  offsetLeft: ComputedRef<number>

  viewportChangeHandler: (event: UIEvent | Event) => void
  resetOffset: () => void
}

export const useVisualViewportHandler = (layoutViewportClass: string): VisualViewportHandlerComposable => {
  const state = reactive({
    offsetLeft: 0,
    offsetTop: 0
  })

  // The pendingUpdate flag serves to prevent multiple invocations of the transform
  // that can occur when onresize and onscroll fire at the same time.
  let pendingUpdate = false

  const viewportChangeHandler = (event: UIEvent | Event) => {
    if (pendingUpdate) return
    pendingUpdate = true

    requestAnimationFrame(() => {
      pendingUpdate = false
      const layoutViewportCandidates = document.getElementsByClassName(layoutViewportClass)

      if (!layoutViewportCandidates?.length) return
      const layoutViewport = layoutViewportCandidates[0] as HTMLElement

      // Since the bar is position: fixed we need to offset it by the
      // visual viewport's offset from the layout viewport origin.
      const viewport = event.target as VisualViewport

      const offsetLeft = viewport.offsetLeft
      const offsetTop =
        viewport.height -
        layoutViewport.getBoundingClientRect().height +
        viewport.offsetTop

      // The breakpoint to apply the offset for the button
      // For some tablet it applies
      const DISABLE_BREAKPOINT = -300
      if (offsetTop < DISABLE_BREAKPOINT) return

      state.offsetLeft = offsetLeft
      state.offsetTop = offsetTop
    })
  }

  const offsetTop = computed(() => state.offsetTop)
  const offsetLeft = computed(() => state.offsetLeft)

  const resetOffset = () => {
    state.offsetLeft = 0
    state.offsetTop = 0
  }

  return {
    viewportChangeHandler,

    offsetTop,
    offsetLeft,
    resetOffset
  }
}
