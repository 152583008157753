<template>
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="72"
      height="72"
      rx="36"
      fill="#D1D1D1"
    />
    <g clip-path="url(#clip0_2124_60417)">
      <circle
        cx="36"
        cy="28.7999"
        r="14.4"
        fill="#F2F2F2"
      />
      <path
        d="M9.59998 76.7999C9.59998 74.5713 9.59998 73.4569 9.67396 72.5169C10.6002 60.7479 19.9479 51.4001 31.717 50.4739C32.657 50.3999 33.7713 50.3999 36 50.3999V50.3999C38.2286 50.3999 39.3429 50.3999 40.283 50.4739C52.052 51.4001 61.3997 60.7479 62.326 72.5169C62.4 73.4569 62.4 74.5713 62.4 76.7999V79.1999H9.59998V76.7999Z"
        fill="#F2F2F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_2124_60417">
        <rect
          width="72"
          height="72"
          rx="36"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
