<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EDialog'
})

const DIALOG_BREAKPOINT = 698</script>

<script setup lang="ts">
import { useQuasar } from 'quasar'

import TestIds from '~cypress/types/testIds'
import { EDialogProps } from './types'
import useAppStore from '~/stores/app'
import useDialogsStore from '~/stores/dialogs'
import { useVisualViewportHandler } from '~/composables/useVisualViewportHandler'

const props = defineProps({
  modelValue: { type: Boolean },
  position: null,
  desktopWidth: { default: '800px' },
  persistent: { type: Boolean },
  toolbar: { type: Boolean, default: true },
  maximized: { type: Boolean, default: false },
  resizeVisualViewport: { type: Boolean, default: false },
  scrollEvent: { type: Boolean, default: false },
  nested: { type: Boolean },
  closable: { type: Boolean, default: true },
  closeIcon: { type: Boolean, default: false },
  hideBackdrop: { type: Boolean, default: undefined }
})

const emit = defineEmits(['update:modelValue', 'displayed', 'close', 'back'])

const dialog = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  }
})

const $q = useQuasar()



const isMobile = computed(() => $q.screen.width <= DIALOG_BREAKPOINT)

const defaultPosition = computed(() => isMobile.value ? 'bottom' : 'standard')
const position = computed(() => props.position || defaultPosition.value)

const parentDialogHeading = ref('')

const maximizedDefault = computed(() => props.position === 'standard' && isMobile.value)
const isMaximized = computed(() => props.maximized || maximizedDefault.value)
const contentContainerStyle = computed(() => isMobile.value
  ? undefined
  : { width: props.desktopWidth }
)

const transitionShow = computed(() => defaultPosition.value === 'standard' || isMaximized.value ? 'none' : undefined)
const transitionHide = computed(() => defaultPosition.value === 'standard'|| isMaximized.value ? 'none' : undefined)

const dialogClasses = computed(() => {
  const utilityClasses = {
    'has-scroll-event': props.scrollEvent,
    'hide-backdrop': props.hideBackdrop
  }

  return {
    ...utilityClasses,
    'e-dialog--nested': props.nested
  }
})

const appStore = useAppStore()

const { setDialogHeading } = useDialogsStore()
const { dialogHeading } = toRefs(useDialogsStore())

const {
  viewportChangeHandler,
  offsetTop, resetOffset
} = useVisualViewportHandler('e-dialog')

const visualViewportTranslateY = useCssVar('--visualViewportTranslateY', document.body)
visualViewportTranslateY.value = `${offsetTop.value}px`

watch(offsetTop, (offsetTopValue) => {
  visualViewportTranslateY.value = `${offsetTopValue}px`
})

const initVisualViewportChangeHandlers = () => {
  if (window.visualViewport != null) {
    window.visualViewport.addEventListener('scroll', viewportChangeHandler)
    window.visualViewport.addEventListener('resize', viewportChangeHandler)
  }
}

const cleanVisualViewportChangeHandlers = () => {
  if (window.visualViewport != null) {
    window.visualViewport.removeEventListener('scroll', viewportChangeHandler)
    window.visualViewport.removeEventListener('resize', viewportChangeHandler)
  }
}

const handleDialogShowEvent = () => {
  // store parent dialog title when nested is open
  if (props.nested) {
    parentDialogHeading.value = dialogHeading.value
  }

  if (props.toolbar) setDialogHeading('')

  appStore.registerActiveDialog()

  if (props.resizeVisualViewport) {
    initVisualViewportChangeHandlers()
  }

  emit('displayed')
}
const handleDialogHideEvent = () => {
  // restore parent dialog title when nested dialog is closed
  setDialogHeading(!props.nested ? '' : parentDialogHeading.value)

  appStore.unregisterActiveDialog()

  if (props.resizeVisualViewport) {
    cleanVisualViewportChangeHandlers()
    resetOffset()
  }
}

const hasBackIcon = computed(() => props.nested && !isMobile.value)
const hasCloseIcon = computed(() => props.closable && (props.closeIcon || !hasBackIcon.value))

const handleCloseIconClickAction = () => {
  emit('close')
}

const handleBackIconClickAction = () => {
  emit('close')
  emit('back')
}

defineExpose({
  position,
  contentContainerStyle,
  dialogClasses,
  parentDialogHeading,
  hasBackIcon,

  transitionShow,
  transitionHide
})
</script>

<template>
  <q-dialog
    v-model="dialog"
    :position="position"
    :persistent="persistent"
    :maximized="isMaximized"
    :full-height="isMaximized"
    :seamless="isMaximized && isMobile"
    :transition-show="transitionShow"
    :transition-hide="transitionHide"
    no-shake
    @show="handleDialogShowEvent"
    @hide="handleDialogHideEvent"
    class="e-dialog font-enbwpro"
    :class="dialogClasses"
    ref="dialogContent"
  >
    <q-card
      :style="contentContainerStyle"
      class="e-dialog__content relative-position column"
    >
      <slot
        name="header"
      >
        <q-toolbar
          class="e-dialog__toolbar"
          :class="{ 'has-heading-toolbar': toolbar && dialogHeading }"
        >
          <template v-if="toolbar">
            <template v-if="hasBackIcon">
              <q-btn
                :data-testId="TestIds.ACTIVE_DIALOG_CLOSE_BACK_ICON"
                data-test="dialog-close-back-icon"
                flat
                round
                dense
                @click="handleBackIconClickAction"
                class="text-tapa"
              >
                <IconArrowLeftNew color="currentColor" />
              </q-btn>
            </template>

            <q-toolbar-title>
              <h3
                v-if="dialogHeading"
                class="t4 text-center"
              >
                {{ dialogHeading }}
              </h3>
            </q-toolbar-title>

            <q-space />

            <template v-if="hasCloseIcon">
              <q-btn
                :data-testId="TestIds.ACTIVE_DIALOG_CLOSE_ICON"
                data-test="dialog-close-icon"
                flat
                round
                dense
                @click="handleCloseIconClickAction"
                class="text-tapa"
              >
                <IconXmarkAlt color="currentColor" />
              </q-btn>
            </template>
          </template>
        </q-toolbar>
      </slot>

      <slot v-bind="{ isMobile, isMaximized }" />
    </q-card>
  </q-dialog>
</template>

<style lang="scss">
@import '~vars';

.e-dialog {

  @media (max-width: 698px) {
    .q-dialog__inner--minimized {
      padding: 0;

      .e-dialog__content {
        max-width: none;
        width: 100%;
      }
    }
  }

  .e-dialog__content {
    background: $white;
    flex-wrap: nowrap;

    h1, h2 {
      margin-bottom: 40px;
    }

    .q-card__actions {
      background: linear-gradient(rgba($white, 0) 0%, $white 100%);
      bottom: 0;
      position: sticky;
      z-index: 10;
      display: block;
      transition: 0.2s;
      transform: translate(0px, var(--visualViewportTranslateY));
      padding-top: 24px;
    }
  }

  &.has-scroll-event {
    .e-dialog__content {
      .q-card__section {
        padding-bottom: 96px;

        @media (min-width: 699px) {
          padding-bottom: 130px;
        }
      }

      .q-card__actions {
        padding: 24px 0;
        position: absolute;
        bottom: 0;
        right: 24px;
        left: 24px;

        @media (min-width: 699px) {
          padding-top: 0;
          padding-bottom: 56px;
          right: 56px;
          left: 56px;
        }
      }
    }
  }

  &:not(.has-scroll-event) {
    .e-dialog__toolbar + form,
    .e-dialog__toolbar + div {
      margin-top: -24px;
    }
  }

  &.hide-backdrop > .q-dialog__backdrop {
    display: none;
  }
}

.q-dialog.q-dialog--modal,
.q-dialog.q-dialog--seamless {
  .e-dialog__toolbar {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
  }

  .has-heading-toolbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

    h3 {
      margin: 0 18px 0 52px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.e-dialog--nested {
    @media (min-width: 699px) {
      .has-heading-toolbar h3 {
        margin-left: 18px;
        margin-right: calc(18px + 32px);
      }
    }
  }

  .q-dialog__inner--bottom .e-dialog__toolbar {
    min-height: 64px;
  }

  .q-dialog__inner--bottom > div {
    padding: 0;
  }

  .q-dialog__inner--bottom {
    &:not(.q-dialog__inner--animating) > div,
    &.q-dialog__inner--animating > div {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
    }
  }

  .e-dialog__toolbar + form,
  .e-dialog__toolbar + div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
  }

  @media (min-width: 699px) {
    .e-dialog__toolbar {
      padding-top: 8px;
      padding-left: 24px;
      padding-right: 24px;
    }

    .q-dialog__inner--standard .e-dialog__toolbar {
      min-height: 72px;
    }

    .e-dialog__toolbar .q-toolbar {
      padding-left: 24px;
      padding-right: 24px;
    }

    .q-dialog__inner--standard > div {
      border-radius: 12px;
      padding: 0;
      min-width: 540px;
      max-width: 800px;
      height: 620px;
    }
  }

  .e-dialog__toolbar + form,
  .e-dialog__toolbar + div {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: 699px) {
    .e-dialog__toolbar + form,
    .e-dialog__toolbar + div {
      padding-left: 56px;
      padding-right: 56px;
    }

    .q-dialog__inner--standard > div {
      padding-left: 0;
      padding-bottom: 0;
      padding-right: 0;
    }
  }
}
</style>
