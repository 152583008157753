<script setup lang="ts">
import { TenantId } from '~/composables/domain/useTenantConfig/types'
import { EDialogContentProps } from '~/components/design-system/EDialog/types'
import TestIds from '~cypress/types/testIds'
import IconEnbw from '~/components/icons/IconEnbw.vue'
import IconYelloApp from '~/components/icons/IconYelloApp.vue'

defineProps({
  mobile: { type: Boolean },
  maximized: { type: Boolean }
})

const emit = defineEmits(['click:tenant-option'])

const { t } = useI18n()

const selectedTenant = ref('')

const itemClickHandler = (tenantId: TenantId) => {
  emit('click:tenant-option', tenantId)
}

const tenantOptions = [{
  iconComponent: IconEnbw,
  title: 'EnBW',
  tenantId: TenantId.Enbw,
  clickHandler: itemClickHandler,
  class: 'e-item q-mb-md'
}, {
  iconComponent: IconYelloApp,
  title: 'Yello',
  tenantId: TenantId.Yello,
  clickHandler: itemClickHandler,
  class: 'e-item'
}]

defineExpose({
  t,
  TestIds,

  selectedTenant
})
</script>

<template>
  <div class="select-tenant-dialog full-height cdc-mb-56">
    <q-card-section class="select-tenant-dialog__body">
      <component
        :is="mobile ? 'h3' : 'h2'"
        class="font-mark cdc-fw-500"
        :class="{ 't3': mobile, 't2': !mobile }"
      >
        {{ t('dialogs.select-tenant.title') }}
      </component>

      <q-list
        :data-testId="TestIds.TENANT_SELECTION_OPTIONS"
        class="q-py-sm"
      >
        <q-item
          v-for="option in tenantOptions"
          :key="option.tenantId"
          clickable
          manual-focus
          @click="() => option.clickHandler(option.tenantId)"
          :class="option.class"
        >
          <q-item-section avatar>
            <component :is="option.iconComponent" />
          </q-item-section>

          <q-item-section>
            <q-item-label class="font-mark cdc-fw-500">
              {{ option.title }}
            </q-item-label>
          </q-item-section>

          <q-item-section side>
            <IconArrowRight />
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
  </div>
</template>

<style lang="scss" scoped>
@import '~vars';

.select-tenant-dialog {
  .select-tenant-dialog__body {
    flex: 1;
  }
}
</style>
