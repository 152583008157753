import LayoutTestIds from './layout'
import DialogsTestIds from './dialogs'
import HomeTestIds from './home'
import PersonalDataTestIds from './personalData'
import ContractsPaymentsTestIds from './contractsPayments'
import DataProtectionTestIds from './dataProtection'
import RegistrationTestIds from './registration'
import PrivacyTestIds from './privacy'
import TenantSelectionTestIds from './tenantSelection'
import TenantUnavailableTestIds from './tenantUnavailable'
import PaperlessCommunicationTestIds from './paperlessCommunication'
import IdentityLinkingTestIds from './identityLinking'

const TestIds = {
  ...LayoutTestIds,
  ...DialogsTestIds,
  ...HomeTestIds,
  ...PersonalDataTestIds,
  ...ContractsPaymentsTestIds,
  ...DataProtectionTestIds,
  ...RegistrationTestIds,
  ...PrivacyTestIds,
  ...TenantSelectionTestIds,
  ...TenantUnavailableTestIds,
  ...PaperlessCommunicationTestIds,
  ...IdentityLinkingTestIds
}

type TestIds = typeof TestIds

export default TestIds
