export enum API_RESPONSE_CODES {
  OK = 200
}

export enum API_URLS {
  GET_USER_PROFILE = 'api/userProfile',
  GET_CUSTOMER_PROFILE = 'api/customerProfile',
  HEALTH_STATUS = 'health',
  PASSWORD_RESET = 'api/userProfile/reset-password',
  EMAIL_RESET = 'api/emailReset',
  PIN_RESET = 'api/pinReset',
  PIN = 'api/userProfile/phonePin',
  SET_PIN_SEEN = 'api/userProfile/phonePinSeen',
  DELETE_MEK_ACCOUNT = 'api/userProfile/create-account-deletion-workitem',
  UPDATE_BIRTHDATE = 'api/personalData/birthdate',
  UPDATE_PHONE_NUMBER = 'api/personalData/phoneNumber',
  UPDATE_EMAIL_ADDRESS = 'api/personalData/emailAddress',
  REPLACE_CONTRACTS_PHONE_NUMBERS = 'api/personalData/contractPhoneNumbers',
  REPLACE_CONTACTS_PHONE_NUMBERS = 'api/personalData/contactPhoneNumbers',
  ECO_PERMISSIONS = 'api/ecoPermissions',
  PERMISSIONS_OVERVIEW = 'api/permissions/overview',
  CREATE_ECO_PERMISSIONS_ACTIVITY = 'api/ecoPermissions/create-activity',
  ADV_PERMISSIONS = 'api/advPermissions',
  CREATE_ADV_PERMISSIONS_ACTIVITY = 'api/advPermissions/create-activity',
  TRACKING_PERMISSIONS = 'api/trackingPermissions',
  CREATE_TRACKING_PERMISSIONS_ACTIVITY = 'api/trackingPermissions/create-activity',
  DELETE_PERMISSIONS_PRODUCT_CONSENT = 'api/permissions/product-consent',
  PAPERLESS_COMMUNICATION = 'api/paperlessContractCommunication/useEmailForContracts',
  IDENTITY_LINKING_GET_ACTIVE_PROCESS_BY_CODE = 'api/identityLinking/processByRegistrationCode',
  IDENTITY_LINKING_ZIP_CODE_VALIDATION = 'api/identityLinking/performZipCodeValidation',
  IDENTITY_LINKING_ELIGIBILITY_CHECK = 'api/identityLinking/performEligibilityCheck',
  IDENTITY_LINKING_RESTART_LETTER_PROCESS = 'api/identityLinking/restartLetterProcess',
  IDENTITY_LINKING_RESTART_PROCESS_WITH_EMAIL = 'api/identityLinking/restartProcessWithEmail',
}

export enum Persona {
  KRISTINA = 'Kristina',
  ANNA = 'Anna',
  EDGE = 'Edge',
  LUISA = 'Luisa', // dedicated for identity linking flows
}
