export enum TenantId {
  Enbw = '100',
  Yello = '200',
  Senec = '500'
}

export enum ContractSystem {
  Emp = 'emp',
  PowerCloud = 'powercloud',
  ScaleCrm = 'scale-crm'
}

export interface LastUsedApplication {
  lastUsedTimestampUtc: string
  name: string
  tenantId: TenantId
}
