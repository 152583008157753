import type { UseFetchReturn } from '@vueuse/core'
import type { ComputedRef } from 'vue'

import { UserProfileResponse, UserProfileErrorResponse } from '~/middleware/api/useUserProfileData'
import usePasswordResetClaim from '~/middleware/utils/usePasswordResetClaim'
import useUserStore from '~/stores/user'
import { RegistrationData } from '~/stores/user/types'
import useUserProfileDataApi from '~api/useUserProfileData'
import { GLOBAL_EVENTS, GLOBAL_EVENTS_GROUP, useGlobalEvents } from '../utils/useGlobalEvents'
import { useBusinessSystems } from '../domain/useBusinessSystems'

let request: null | UseFetchReturn<UserProfileResponse | UserProfileErrorResponse> = null

const Status403Forbidden = 403

const eventBus = useGlobalEvents(GLOBAL_EVENTS_GROUP.USER)

export function hasValue(instance: any) {
  return instance !== undefined && instance !== null
}

export interface UserRegistrationDataComposable {
  isLoaded: ComputedRef<boolean>
  isLoadedAndTenantDefined: ComputedRef<boolean>
  registrationData: ComputedRef<RegistrationData | undefined>
}

export const useUserRegistrationData: () => UserRegistrationDataComposable = () => {
  const store = useUserStore()

  const isLoaded = computed(() => !!store.registrationData)

  const isLoadedAndTenantDefined = computed(() => !!store.registrationData && !!store.tenantId)

  const registrationData = computed(() => store.registrationData)

  return {
    isLoaded,
    isLoadedAndTenantDefined,
    registrationData
  }
}

const useUserRegistrationDataDefault = async (): Promise<RegistrationData | undefined> => {
  const store = useUserStore()

  const { updateBusinessSystemsConfig } = useBusinessSystems(store.tenantId)

  if (store.registrationData) {
    return store.registrationData
  }

  const abortAfterDefaultTimeout = true

  // make sure only one request is made
  // when request for registration data comes after one request started
  if (!request) {
    request = useUserProfileDataApi({ abortAfterDefaultTimeout }) as UseFetchReturn<UserProfileResponse | UserProfileErrorResponse>
  }

  const { data, error } = await request

  if (error.value && error.value.name === 'AbortError') {
    request = null
    throw error.value
  }

  if(error.value && !data.value) {
    request = null
    return undefined
  }

  const registrationData: RegistrationData = reactive({
    email: ''
  })

  if (data.value.status === Status403Forbidden) {
    eventBus.emit(GLOBAL_EVENTS.USER_TENANT_SENEC)
  } else if (data.value) {
      const { eMailAddress, lastPasswordResetDate, lastUsedApplication } = data.value
      const passwordResetDateClaim = await usePasswordResetClaim()
      let newPasswordResetDate = undefined
      if (hasValue(lastPasswordResetDate)) {
        newPasswordResetDate = new Date(lastPasswordResetDate)
      } else if (hasValue(passwordResetDateClaim)) {
        newPasswordResetDate = new Date(passwordResetDateClaim)
      }

      registrationData.email = eMailAddress
      registrationData.lastPasswordResetDate = newPasswordResetDate
      registrationData.lastUsedApplication = lastUsedApplication

      store.setRegistrationData(registrationData)

      updateBusinessSystemsConfig(data.value.businessSystems)

      if (registrationData.lastUsedApplication && !!registrationData.lastUsedApplication.tenantId) {
        eventBus.emit(GLOBAL_EVENTS.USER_TENANT_DEFINED)
      } else {
        eventBus.emit(GLOBAL_EVENTS.USER_TENANT_UNKNOWN)
      }
  }

  request = null

  return registrationData
}

export default useUserRegistrationDataDefault
