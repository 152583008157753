import { GLOBAL_EVENTS } from '~/composables/utils/useGlobalEvents'

export function globalEventsListenerHelper(
  event: string,
  openSelectTenantDialogHelper: () => void,
  openSenecTenantUnavailableDialogHelper: () => void
) {
  if (event === GLOBAL_EVENTS.USER_TENANT_UNKNOWN)
    openSelectTenantDialogHelper()

  if (event === GLOBAL_EVENTS.USER_TENANT_SENEC)
    openSenecTenantUnavailableDialogHelper()
}
