<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EButton'
})
</script>

<script setup lang="ts">
import { EButtonProps } from './types'

import IconButtonLoading from '~/components/icons/IconButtonLoading.vue'

const props = defineProps({
  text: null,
  color: null,
  onButtonClick: { type: Function },
  isLoading: { type: Boolean },
  hasArrow: { type: Boolean },
  fullWidth: { type: Boolean, default: true },
  size: { default: 'medium' }
})

const btnSizeClass = computed(() => `e-btn--${props.size}`)

const btnClass = computed(() => ({
  [btnSizeClass.value]: true,
  'full-width': props.fullWidth,
  'has-arrow': props.hasArrow
}))

defineExpose({
  btnClass
})
</script>

<template>
  <q-btn
    unelevated
    no-caps
    rounded
    :color="color"
    :loading="isLoading"
    @click="onButtonClick"
    class="e-btn"
    :class="btnClass"
  >
    <div class="row items-center">
      <slot name="default">
        <div>
          {{ text }}
        </div>

        <IconArrowRight
          v-if="hasArrow"
          color="#ffffff"
          class="q-ml-xs"
        />
      </slot>
    </div>
    <template #loading>
      <IconButtonLoading
        width="24"
        height="24"
      />
    </template>
  </q-btn>
</template>

<style lang="scss" scoped>
@import '~vars';
@import '~utilities';

.e-btn {
  &.e-btn--medium {
    @extend .button-medium;
    padding: 12px 28px;
  }

  &.e-btn--small {
    @extend .button-small;
    padding: 12px 28px;
  }

  &.e-btn--large {
    @extend .button-large;
    padding: 16px 32px;
    border-radius: 80px;
  }
}

/* primary button styles */
.e-btn.bg-primary-blue {
  &:hover,
  &:focus {
    background-color: $secondary-azur-60 !important;
  }

  &:active {
    background-color: $secondary-azur-90 !important;
  }

  &[disabled],
  &.disabled {
    background-color: #8080CC !important;
    opacity: 1 !important;
  }
}

/* hero button styles */
.e-btn.bg-red {
  &:hover {
    background-color: $tarocco-60 !important;
  }

  &[disabled],
  &.disabled {
    background-color: #E8CAC2 !important;
    opacity: 1 !important;
  }

  &:active,
  &:focus {
    background-color: $tarocco-80 !important;
  }
}

.e-btn.e-btn--medium.has-arrow {
  padding-right: 16px;
}

.e-btn.e-btn--small.has-arrow {
  padding-right: 12px;
}

.e-btn.e-btn--large.has-arrow {
  padding-right: 28px;
}
</style>
