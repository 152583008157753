import TestIds from '~cypress/types/testIds'
import { QNotifyCreateOptions, useQuasar } from 'quasar'
import { ToastNotificationType } from '~/utils/registerNotificationTypes/types'
import { insertLink, getOptionsForToastWithReferrer, getToastPosition} from './toast-utils'
import useReferrer from '../useReferrer'

const DIALOG_BREAKPOINT = 698

const useToastNotifications = () => {
  const { t } = useI18n()
  const $q = useQuasar()
  const { referrerURL, referrerName, computeReferrerBackURL } = useReferrer()
  const isMobile = computed(() => $q.screen.width <= DIALOG_BREAKPOINT)

  const addLinkBack = (message: string|undefined) => {
    const refererText = t('layout.toast.touchpoint-link', { name: referrerName.value })
    return insertLink(message, refererText)
  }

  const notify = (opts: QNotifyCreateOptions, mobilePositiononTop = false) =>{
    const isNegativeToast = opts.type === ToastNotificationType.NEGATIVE
    const position = getToastPosition(isMobile.value, isNegativeToast, mobilePositiononTop)

    if(referrerURL.value && !isNegativeToast){
      const msg = addLinkBack(opts.message)

      $q.notify({
        position,
        ...opts,
        ...getOptionsForToastWithReferrer(msg, computeReferrerBackURL(opts.type))
      })
    }else{
      $q.notify({
        position,
        ...opts
      })
    }
  }

  const changePhoneNumberErrorToast = () => {
    notify({
      message: `
        <div data-testId="${TestIds.PERSONAL_DATA_EDIT_PHONE_ERROR_TOAST}" class="toast-content">
          <div class="cdc-fw-500">${t('edit-page.phone-number.toast.error.title')}</div>
          <div>${t('edit-page.phone-number.toast.error.content')}</div>
        </div>`,
      type: ToastNotificationType.NEGATIVE
    }, true)
  }
  const changePhoneNumberPartialSuccessToast = () => {
    notify({
      message: `
        <div data-testId="${TestIds.PERSONAL_DATA_EDIT_PHONE_ERROR_TOAST}" class="toast-content">
          <div class="cdc-fw-500">${t('edit-page.phone-number.toast.partial-error.title')}</div>
          <div>${t('edit-page.phone-number.toast.partial-error.content')}</div>
        </div>`,
      type: ToastNotificationType.PARTIAL_POSITIVE
    }, true)
  }
  const changePhoneNumberSuccessToast = () => {
    notify({
      message: `
        <div data-testId="${TestIds.PERSONAL_DATA_EDIT_PHONE_SUCCESS_TOAST}" class="toast-content">
          <div class="cdc-fw-500">${t('edit-page.phone-number.toast.success.title')}</div>
        </div>`,
      type: ToastNotificationType.POSITIVE
    }, true)
  }

  const showUpdateEmailAddressErrorToast = () => {
    notify({
      message: `
        <div data-testId="${TestIds.PERSONAL_DATA_EDIT_EMAIL_ERROR_TOAST}" class="toast-content">
          <div class="cdc-fw-500">${t('dialogs.edit-email.toast.error.title')}</div>
          <div>${t('dialogs.edit-email.toast.error.content')}</div>
        </div>`,
      type: ToastNotificationType.NEGATIVE
    }, true)
  }
  const showUpdateEmailAddressPartialSuccessToast = () => {
    notify({
      message: `
        <div data-testId="${TestIds.PERSONAL_DATA_EDIT_EMAIL_ERROR_TOAST}" class="toast-content">
          <div class="cdc-fw-500">${t('dialogs.edit-email.toast.partial-error.title')}</div>
          <div>${t('dialogs.edir-email.toast.partial-error.content')}</div>
        </div>`,
      type: ToastNotificationType.PARTIAL_POSITIVE
    }, true)
  }
  const showUpdateEmailAddressSuccessToast = () => {
    notify({
      message: `
        <div data-testId="${TestIds.PERSONAL_DATA_EDIT_EMAIL_SUCCESS_TOAST}" class="toast-content">
          <div class="cdc-fw-500">${t('dialogs.edit-email.toast.success.title')}</div>
        </div>`,
      type: ToastNotificationType.POSITIVE
    }, true)
  }



  return {
    changePhoneNumberErrorToast,
    changePhoneNumberPartialSuccessToast,
    changePhoneNumberSuccessToast,
    showUpdateEmailAddressErrorToast,
    showUpdateEmailAddressPartialSuccessToast,
    showUpdateEmailAddressSuccessToast,
    notify
  }
}

export default useToastNotifications
