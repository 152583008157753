enum LayoutTestIds {
  APP_HEADER = 'appHeader',
  APP_NAVIGATION_HEADER = 'appNavigationHeader',
  APP_HEADER_DETAIL_TITLE = 'appHeaderDetailTitle',
  APP_FOOTER = 'appFooter',
  APP_SIDEBAR = 'appSidebar',
  PROFILE_INFO = 'profileInfo',
  PROFILE_PICTURE = 'profilePicture',
  HEADER_PROFILE_PICTURE = 'headerProfilePicture',
  LOGOUT_DIALOG_PROFILE_PICTURE = 'logoutDialogProfilePicture',
  NAV_TABS = 'navTabs',
  TAB_OVERVIEW = 'tabOverview',
  TAB_PERSONAL_DATA = 'tabPersonalData',
  TAB_CONTRACTS_PAYMENTS = 'tabContractsPayments',
  TAB_REGISTRATION = 'tabRegistration',
  TAB_PRIVACY = 'tabPrivacy',
  PROFILE_EMAIL = 'profileEmail',
  HEADER_BACK_BUTTON = 'headerBackButton',
  LOGOUT_DIALOG = 'logoutDialog',
  LOGOUT_POPUP = 'logoutPopup',
  LOGOUT_ABORT = 'logoutAbort',
  NOT_FOUND_PAGE = 'notFoundPage',
  UNAVAILABLE_PAGE = 'unavailablePage',

  APP_BREADCRUMBS = 'appBreadcrumbs',
  APP_BREADCRUMBS_ITEM = 'appBreadcrumbsItem',

  TOAST_REFERRER_LINK = 'toastReferrer'
}

export default LayoutTestIds
