import type { UseFetchOptions, UseFetchReturn } from '@vueuse/core'

import useFetch, { DEFAULT_TIMEOUT } from '~/middleware/utils/useFetch'
import { API_URLS } from '~/middleware/utils/useFetch/types'
import useAppConfig from '~/composables/useAppConfig'
import type { LastUsedApplication, TenantId } from '~/composables/domain/useTenantConfig/types'

export interface UserBusinessSystem {
  enabled: boolean
}

export interface UserBusinessSystems {
  [TenantId.Yello]: { phonePin: UserBusinessSystem }
  [TenantId.Enbw]: { phonePin: UserBusinessSystem }
}

export interface UserProfileResponse {
  eMailAddress: string
  lastPasswordResetDate?: string
  lastUsedApplication?: LastUsedApplication
  businessSystems: UserBusinessSystems,
}

export interface UserProfileErrorResponse {
  detail: string,
  status: number,
  title: string,
  traceId: string,
  type: string,
}

const useUserProfileData = ({ abortAfterDefaultTimeout } = { abortAfterDefaultTimeout: false }): UseFetchReturn<UserProfileResponse> => {
  const appConfig = useAppConfig()

  const apiFetch = useFetch({
    needAuthorization: true,
    hasMockData: appConfig?.IS_AUTH0_MOCKED
  })

  const useFetchOptions: UseFetchOptions = {}

  if (abortAfterDefaultTimeout) {
    useFetchOptions.timeout = DEFAULT_TIMEOUT
  }

  return apiFetch(API_URLS.GET_USER_PROFILE, useFetchOptions).json()
}

export default useUserProfileData
