<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LogoutDialog'
})
</script>

<script setup lang="ts">
import useDialogsStore from '~/stores/dialogs'
import TestIds from '~cypress/types/testIds'

const { isLogoutDialogOpen } = toRefs(useDialogsStore())
</script>

<template>
  <EDialog
    :data-testId="TestIds.LOGOUT_DIALOG"
    v-model="isLogoutDialogOpen"
    position="bottom"
    :toolbar="false"
  >
    <LogoutDialogContent />
  </EDialog>
</template>
