enum IdentityLinkingTestIds {
  IDENTITY_LINKING_PAGE = 'identityLinkingPage',
  IDENTITY_LINKING_ELIGIBILITY_CHECK_PROGRESS = 'identityLinkingEligibilityCheckProgress',
  IDENTITY_LINKING_ELIGIBILITY_CHECK_ACCOUNT_ALREADY_EXISTS = 'identityLinkingEligibilityCheckAccountAlreadyExists',
  IDENTITY_LINKING_ELIGIBILITY_CHECK_ACCOUNT_ALREADY_EXISTS_CTA = 'identityLinkingEligibilityCheckAccountAlreadyExistsCta',
  IDENTITY_LINKING_ELIGIBILITY_CHECK_PROCESS_EXPIRED = 'identityLinkingEligibilityCheckProcessExpired',
  IDENTITY_LINKING_ELIGIBILITY_CHECK_PROCESS_EXPIRED_CTA = 'identityLinkingEligibilityCheckProcessExpiredCta',
  IDENTITY_LINKING_LETTER_RENEWAL_OPTIONS_DIALOG = 'identityLinkingLetterRenewalOptionsDialog',
  IDENTITY_LINKING_LETTER_RENEWAL_OPTIONS_DIALOG_CTA_EMAIL = 'identityLinkingLetterRenewalOptionsDialogCtaEmail',
  IDENTITY_LINKING_LETTER_RENEWAL_OPTIONS_DIALOG_CTA_LETTER = 'identityLinkingLetterRenewalOptionsDialogCtaLetter',
  IDENTITY_LINKING_EMAIL_RENEWAL_FORM_DIALOG = 'identityLinkingEmailRenewalFormDialog',
  IDENTITY_LINKING_RESTART_PROCESS_SUCCESS = 'identityLinkingRestartProcessSuccess',
  IDENTITY_LINKING_RESTART_PROCESS_WITH_EMAIL_SUCCESS_TOAST = 'identityLinkingRestartProcessWithEmailSuccessToast',
  IDENTITY_LINKING_ZIP_CODE_VERIFICATION_DIALOG = 'identityLinkingZipCodeVerificationDialog',
  IDENTITY_LINKING_ZIP_CODE_VERIFICATION_DIALOG_FIELD = 'identityLinkingZipCodeVerificationDialogField',
  IDENTITY_LINKING_ZIP_CODE_VERIFICATION_DIALOG_SUBMIT = 'identityLinkingZipCodeVerificationDialogSubmit',
  IDENTITY_LINKING_PROCESS_SUCCESS = 'identityLinkingProcessSuccess',
  IDENTITY_LINKING_PROCESS_SUCCESS_CTA = 'identityLinkingProcessSuccessCta',
}

export default IdentityLinkingTestIds
