<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="48"
      height="48"
      rx="12"
      fill="#FFF100"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.4452 16.017V28.5122H24.8547V16.017H27.4452ZM23.788 24.2116C23.788 20.8592 21.9087 19.2507 19.369 19.2507C16.9648 19.2507 14.7129 20.91 14.7129 24.0253C14.7129 26.9714 16.6261 28.783 19.4536 28.783C21.0282 28.783 22.2473 28.3089 23.2462 27.3947L22.0103 25.6169C21.2822 26.2433 20.5372 26.4973 19.7076 26.4973C18.4208 26.4973 17.642 25.9386 17.405 24.7195H23.7711C23.788 24.5502 23.788 24.3809 23.788 24.2116ZM19.3521 21.3502C20.4187 21.3502 20.9944 22.0613 21.1468 23.0772H17.4388C17.6759 22.0105 18.37 21.3502 19.3521 21.3502ZM10.3108 24.6011L8.27904 19.5217H5.33301L8.99015 27.4286L7.02613 32H9.73512L11.1743 28.5122L15.1193 19.5387H12.2917L10.3108 24.6011ZM31.2039 28.5122V16.017H28.6134V28.5122H31.2039ZM41.9044 24.0084C41.9044 27.1068 39.6187 28.783 37.096 28.783C34.624 28.783 32.2875 27.1238 32.2875 24.0084C32.2875 20.8931 34.5902 19.2507 37.1298 19.2507C39.5679 19.2507 41.9044 20.8761 41.9044 24.0084ZM34.9457 24.0253C34.9457 25.6338 35.9616 26.5312 37.096 26.5312C38.1796 26.5312 39.2293 25.6338 39.2462 23.9915C39.2462 22.383 38.2473 21.4857 37.1298 21.4857C36.0293 21.4857 34.9457 22.383 34.9457 24.0253Z"
      fill="black"
    />
  </svg>
</template>
