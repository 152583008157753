<template>
  <svg
    width="186"
    height="12"
    viewBox="0 0 186 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.314453 10.7463V0.0913086H7.12345V1.5429H1.93066V4.65558H6.36025V6.09221H1.93066V9.29468H7.12345V10.7463H0.314453Z"
      fill="#C2C2C2"
    />
    <path
      d="M9.1738 10.7455V3.15834H10.7002V10.7455H9.1738ZM9.12891 1.66186V0.0456543H10.7451V1.66186H9.12891Z"
      fill="#C2C2C2"
    />
    <path
      d="M17.7706 10.7368V6.11264C17.7706 5.52402 17.6235 5.09503 17.3292 4.82567C17.0348 4.5563 16.6732 4.42161 16.2442 4.42161C15.8152 4.42161 15.4461 4.55879 15.1368 4.83315C14.8275 5.1075 14.6729 5.534 14.6729 6.11264V10.7368H13.1465V3.1496H14.643V3.91281C14.9024 3.62349 15.2091 3.40899 15.5633 3.26932C15.9175 3.12965 16.2841 3.05981 16.6632 3.05981C17.0523 3.05981 17.404 3.11967 17.7182 3.23939C18.0325 3.35911 18.3044 3.52871 18.5338 3.7482C18.8231 4.03752 19.0227 4.35926 19.1324 4.71343C19.2422 5.0676 19.297 5.45918 19.297 5.88817V10.7368H17.7706Z"
      fill="#C2C2C2"
    />
    <path
      d="M32.0748 7.70689C32.0748 8.19574 31.98 8.63471 31.7905 9.0238C31.6009 9.41288 31.3365 9.74211 30.9973 10.0115C30.6581 10.2808 30.2541 10.4854 29.7852 10.625C29.3163 10.7647 28.8025 10.8345 28.2438 10.8345C27.4357 10.8345 26.7348 10.7298 26.1412 10.5203C25.5476 10.3108 24.9914 9.94663 24.4727 9.42785L25.5352 8.36534C25.9242 8.75443 26.3433 9.0238 26.7922 9.17344C27.2412 9.32309 27.735 9.39792 28.2737 9.39792C28.9821 9.39792 29.5258 9.25575 29.9049 8.97142C30.284 8.68709 30.4736 8.28054 30.4736 7.75178C30.4736 7.25296 30.3339 6.88382 30.0545 6.64439C29.7952 6.41493 29.4061 6.26528 28.8873 6.19544L27.6302 6.01586C26.7423 5.87619 26.0739 5.59186 25.625 5.16287C25.0962 4.68399 24.8318 4.0006 24.8318 3.11269C24.8318 2.65376 24.9141 2.23225 25.0787 1.84816C25.2433 1.46406 25.4778 1.13483 25.7821 0.860478C26.0864 0.586122 26.458 0.374121 26.897 0.224472C27.3359 0.074824 27.8348 0 28.3934 0C29.1118 0 29.7278 0.0947772 30.2416 0.284332C30.7554 0.473886 31.2418 0.783159 31.7007 1.21215L30.6831 2.22976C30.3538 1.91051 29.9947 1.69352 29.6056 1.57879C29.2165 1.46406 28.7925 1.40669 28.3336 1.40669C27.7051 1.40669 27.2262 1.56133 26.897 1.8706C26.5677 2.17988 26.4031 2.57894 26.4031 3.06779C26.4031 3.26732 26.4331 3.45189 26.4929 3.62149C26.5528 3.79109 26.6525 3.93575 26.7922 4.05547C26.9319 4.17519 27.089 4.27246 27.2636 4.34728C27.4382 4.42211 27.6701 4.48446 27.9595 4.53434L29.2315 4.72889C29.7203 4.8087 30.1144 4.90846 30.4137 5.02818C30.713 5.1479 30.9774 5.31252 31.2068 5.52202C31.7855 6.05078 32.0748 6.77907 32.0748 7.70689Z"
      fill="#C2C2C2"
    />
    <path
      d="M35.1638 7.38465C35.1638 8.04311 35.3284 8.56189 35.6576 8.941C35.9869 9.32011 36.4657 9.50966 37.0943 9.50966C37.5233 9.50966 37.8699 9.44731 38.1343 9.3226C38.3987 9.19789 38.6656 9.00085 38.9349 8.73149L39.9077 9.64434C39.7081 9.84387 39.5111 10.016 39.3165 10.1606C39.122 10.3053 38.9125 10.4275 38.688 10.5273C38.4635 10.627 38.2191 10.7019 37.9547 10.7517C37.6904 10.8016 37.3936 10.8266 37.0643 10.8266C36.5855 10.8266 36.139 10.7592 35.725 10.6245C35.311 10.4899 34.9518 10.2704 34.6475 9.96609C34.3432 9.6618 34.1013 9.26025 33.9217 8.76142C33.7421 8.26259 33.6523 7.65402 33.6523 6.93571C33.6523 5.68864 33.9442 4.73089 34.5278 4.06246C35.1114 3.39403 35.8921 3.05981 36.8698 3.05981C37.3786 3.05981 37.8325 3.14711 38.2316 3.3217C38.6307 3.49629 38.9674 3.7457 39.2417 4.06994C39.5161 4.39418 39.7256 4.78327 39.8702 5.2372C40.0149 5.69113 40.0872 6.19245 40.0872 6.74116V7.38465H35.1638ZM38.5758 6.32215C38.5558 6.09269 38.5359 5.90563 38.5159 5.76097C38.496 5.61631 38.4461 5.45918 38.3663 5.28958C38.2366 5.00026 38.0445 4.7683 37.7901 4.59371C37.5357 4.41912 37.2289 4.33183 36.8698 4.33183C36.5007 4.33183 36.1889 4.41912 35.9345 4.59371C35.6801 4.7683 35.488 5.00026 35.3583 5.28958C35.2785 5.45918 35.2286 5.61631 35.2087 5.76097C35.1887 5.90563 35.1738 6.09269 35.1638 6.32215H38.5758Z"
      fill="#C2C2C2"
    />
    <path
      d="M46.2768 4.93042C46.1071 4.76082 45.94 4.63362 45.7754 4.54882C45.6108 4.46402 45.3988 4.42161 45.1394 4.42161C44.7204 4.42161 44.3638 4.57376 44.0694 4.87804C43.7751 5.18233 43.628 5.59885 43.628 6.12761V10.7368H42.1016V3.1496H43.598V3.97267C43.7976 3.7033 44.0769 3.48382 44.4361 3.31422C44.7952 3.14462 45.1893 3.05981 45.6183 3.05981C45.9874 3.05981 46.3167 3.11469 46.606 3.22443C46.8953 3.33417 47.1697 3.51874 47.429 3.77813L46.2768 4.93042Z"
      fill="#C2C2C2"
    />
    <path
      d="M51.6076 10.7383H50.3655L47.582 3.15112H49.1982L50.994 8.49357L52.7748 3.15112H54.391L51.6076 10.7383Z"
      fill="#C2C2C2"
    />
    <path
      d="M55.7558 10.7455V3.15834H57.2822V10.7455H55.7558ZM55.7109 1.66186V0.0456543H57.3271V1.66186H55.7109Z"
      fill="#C2C2C2"
    />
    <path
      d="M65.1995 9.7491C64.8404 10.1282 64.4663 10.4026 64.0772 10.5722C63.6881 10.7418 63.2292 10.8266 62.7004 10.8266C62.2714 10.8266 61.8549 10.7567 61.4508 10.6171C61.0468 10.4774 60.6876 10.2529 60.3734 9.94364C60.0591 9.63437 59.8047 9.23281 59.6102 8.73897C59.4156 8.24513 59.3184 7.64404 59.3184 6.93571C59.3184 6.22737 59.4156 5.62628 59.6102 5.13244C59.8047 4.6386 60.0591 4.23954 60.3734 3.93526C60.6876 3.63097 61.0468 3.40899 61.4508 3.26932C61.8549 3.12965 62.2714 3.05981 62.7004 3.05981C63.2292 3.05981 63.6881 3.14212 64.0772 3.30673C64.4663 3.47135 64.8404 3.74321 65.1995 4.12232L64.152 5.13993C63.9126 4.88054 63.6856 4.69597 63.4711 4.58623C63.2566 4.47649 62.9997 4.42161 62.7004 4.42161C62.1018 4.42161 61.6329 4.64609 61.2937 5.09503C61.1241 5.30454 61.0069 5.55146 60.942 5.83579C60.8772 6.12012 60.8448 6.48676 60.8448 6.93571C60.8448 7.38465 60.8772 7.75379 60.942 8.04311C61.0069 8.33243 61.1241 8.58184 61.2937 8.79135C61.6329 9.24029 62.1018 9.46476 62.7004 9.46476C62.9997 9.46476 63.2566 9.40989 63.4711 9.30015C63.6856 9.19041 63.9126 9.00584 64.152 8.74645L65.1995 9.7491Z"
      fill="#C2C2C2"
    />
    <path
      d="M67.863 7.38465C67.863 8.04311 68.0276 8.56189 68.3569 8.941C68.6861 9.32011 69.165 9.50966 69.7935 9.50966C70.2225 9.50966 70.5692 9.44731 70.8335 9.3226C71.0979 9.19789 71.3648 9.00085 71.6342 8.73149L72.6069 9.64434C72.4073 9.84387 72.2103 10.016 72.0158 10.1606C71.8212 10.3053 71.6117 10.4275 71.3872 10.5273C71.1628 10.627 70.9183 10.7019 70.654 10.7517C70.3896 10.8016 70.0928 10.8266 69.7635 10.8266C69.2847 10.8266 68.8382 10.7592 68.4242 10.6245C68.0102 10.4899 67.651 10.2704 67.3467 9.96609C67.0424 9.6618 66.8005 9.26025 66.6209 8.76142C66.4414 8.26259 66.3516 7.65402 66.3516 6.93571C66.3516 5.68864 66.6434 4.73089 67.227 4.06246C67.8106 3.39403 68.5913 3.05981 69.569 3.05981C70.0778 3.05981 70.5317 3.14711 70.9308 3.3217C71.3299 3.49629 71.6666 3.7457 71.9409 4.06994C72.2153 4.39418 72.4248 4.78327 72.5695 5.2372C72.7141 5.69113 72.7864 6.19245 72.7864 6.74116V7.38465H67.863ZM71.275 6.32215C71.255 6.09269 71.2351 5.90563 71.2151 5.76097C71.1952 5.61631 71.1453 5.45918 71.0655 5.28958C70.9358 5.00026 70.7437 4.7683 70.4893 4.59371C70.2349 4.41912 69.9282 4.33183 69.569 4.33183C69.1999 4.33183 68.8881 4.41912 68.6337 4.59371C68.3793 4.7683 68.1873 5.00026 68.0576 5.28958C67.9777 5.45918 67.9279 5.61631 67.9079 5.76097C67.888 5.90563 67.873 6.09269 67.863 6.32215H71.275Z"
      fill="#C2C2C2"
    />
    <path
      d="M82.712 10.7463V9.93817C82.4227 10.2873 82.1209 10.5243 81.8066 10.649C81.4923 10.7737 81.1257 10.8361 80.7067 10.8361C80.3276 10.8361 79.9759 10.7762 79.6517 10.6565C79.3274 10.5368 79.0606 10.3721 78.851 10.1626C78.4819 9.78353 78.2475 9.30965 78.1477 8.74098C78.0479 8.17232 77.998 7.57373 77.998 6.9452C77.998 6.63593 78.008 6.32915 78.028 6.02487C78.0479 5.72058 78.0878 5.43126 78.1477 5.15691C78.2076 4.88255 78.2948 4.62565 78.4096 4.38622C78.5243 4.14678 78.6715 3.93228 78.851 3.74273C79.0606 3.53322 79.3249 3.36861 79.6442 3.24889C79.9634 3.12917 80.3126 3.06931 80.6917 3.06931C81.1008 3.06931 81.4649 3.12917 81.7842 3.24889C82.1034 3.36861 82.4027 3.59308 82.6821 3.92231V0.0913086H84.2085V10.7463H82.712ZM82.6821 6.9452C82.6821 6.606 82.6646 6.28426 82.6297 5.97997C82.5948 5.67569 82.5224 5.40881 82.4127 5.17935C82.3029 4.94989 82.1433 4.76782 81.9338 4.63314C81.7243 4.49845 81.4499 4.43111 81.1107 4.43111C80.7616 4.43111 80.4822 4.49845 80.2727 4.63314C80.0632 4.76782 79.9036 4.94989 79.7938 5.17935C79.6841 5.40881 79.6118 5.67569 79.5768 5.97997C79.5419 6.28426 79.5245 6.606 79.5245 6.9452C79.5245 7.28441 79.5419 7.60615 79.5768 7.91043C79.6118 8.21472 79.6841 8.48409 79.7938 8.71854C79.9036 8.95298 80.0632 9.13755 80.2727 9.27223C80.4822 9.40692 80.7616 9.47426 81.1107 9.47426C81.4499 9.47426 81.7243 9.40692 81.9338 9.27223C82.1433 9.13755 82.3029 8.95298 82.4127 8.71854C82.5224 8.48409 82.5948 8.21472 82.6297 7.91043C82.6646 7.60615 82.6821 7.28441 82.6821 6.9452Z"
      fill="#C2C2C2"
    />
    <path
      d="M87.7751 7.38465C87.7751 8.04311 87.9397 8.56189 88.269 8.941C88.5982 9.32011 89.0771 9.50966 89.7056 9.50966C90.1346 9.50966 90.4813 9.44731 90.7457 9.3226C91.01 9.19789 91.2769 9.00085 91.5463 8.73149L92.519 9.64434C92.3195 9.84387 92.1224 10.016 91.9279 10.1606C91.7333 10.3053 91.5238 10.4275 91.2994 10.5273C91.0749 10.627 90.8305 10.7019 90.5661 10.7517C90.3017 10.8016 90.0049 10.8266 89.6757 10.8266C89.1968 10.8266 88.7503 10.7592 88.3363 10.6245C87.9223 10.4899 87.5631 10.2704 87.2588 9.96609C86.9546 9.6618 86.7126 9.26025 86.533 8.76142C86.3535 8.26259 86.2637 7.65402 86.2637 6.93571C86.2637 5.68864 86.5555 4.73089 87.1391 4.06246C87.7228 3.39403 88.5034 3.05981 89.4811 3.05981C89.9899 3.05981 90.4439 3.14711 90.8429 3.3217C91.242 3.49629 91.5787 3.7457 91.8531 4.06994C92.1274 4.39418 92.3369 4.78327 92.4816 5.2372C92.6262 5.69113 92.6986 6.19245 92.6986 6.74116V7.38465H87.7751ZM91.1871 6.32215C91.1672 6.09269 91.1472 5.90563 91.1273 5.76097C91.1073 5.61631 91.0574 5.45918 90.9776 5.28958C90.8479 5.00026 90.6559 4.7683 90.4015 4.59371C90.1471 4.41912 89.8403 4.33183 89.4811 4.33183C89.112 4.33183 88.8002 4.41912 88.5458 4.59371C88.2914 4.7683 88.0994 5.00026 87.9697 5.28958C87.8899 5.45918 87.84 5.61631 87.82 5.76097C87.8001 5.90563 87.7851 6.09269 87.7751 6.32215H91.1871Z"
      fill="#C2C2C2"
    />
    <path
      d="M98.8881 4.93042C98.7185 4.76082 98.5514 4.63362 98.3868 4.54882C98.2221 4.46402 98.0101 4.42161 97.7507 4.42161C97.3317 4.42161 96.9751 4.57376 96.6808 4.87804C96.3864 5.18233 96.2393 5.59885 96.2393 6.12761V10.7368H94.7129V3.1496H96.2094V3.97267C96.4089 3.7033 96.6882 3.48382 97.0474 3.31422C97.4065 3.14462 97.8006 3.05981 98.2296 3.05981C98.5988 3.05981 98.928 3.11469 99.2173 3.22443C99.5066 3.33417 99.781 3.51874 100.04 3.77813L98.8881 4.93042Z"
      fill="#C2C2C2"
    />
    <path
      d="M143.666 6.38943C143.081 5.77338 142.755 4.94407 142.756 4.07861C142.756 2.25664 144.178 0.777614 145.933 0.776367H151.655V2.75921H145.933C145.245 2.77791 144.695 3.36279 144.695 4.07861C144.695 4.79443 145.245 5.38055 145.933 5.39801L149.733 5.39676V7.3796H145.933C145.241 7.39207 144.686 7.97944 144.686 8.699C144.686 9.41731 145.241 10.0047 145.933 10.0172H151.655V12H145.933C144.179 11.9987 142.756 10.5222 142.756 8.699C142.755 7.83478 143.081 7.00548 143.666 6.38943ZM158.307 6.05272C159.004 6.05771 159.568 6.64508 159.569 7.36962V12H161.479V7.36962C161.478 5.55015 160.059 4.07487 158.307 4.06988H153.213V12H155.122V6.05272H158.307ZM180.421 11.056C180.995 11.6408 181.868 12 182.734 12C184.49 12 185.999 10.4798 186.001 8.6566V0.776367H184.092V8.6566C184.091 9.38364 183.435 10.0196 182.734 10.0196C182.034 10.0196 181.379 9.38364 181.378 8.6566V4.07985H179.468V8.6566C179.467 9.38364 178.812 10.0196 178.112 10.0196C177.411 10.0196 176.756 9.38364 176.753 8.6566V0.776367H174.844V8.6566C174.845 10.4798 176.356 12 178.112 12C178.978 12 179.847 11.6408 180.421 11.056ZM165.309 2.75921H169.762C170.466 2.75921 171.034 3.35032 171.034 4.07861C171.034 4.80814 170.466 5.39801 169.762 5.39801L167.217 5.39676V7.3796H169.762C170.465 7.38085 171.032 7.97196 171.032 8.699C171.031 9.42729 170.463 10.0172 169.762 10.0196H163.402V12H169.762C171.518 11.9987 172.941 10.5222 172.941 8.699C172.941 7.79862 172.593 6.98553 172.031 6.39067C172.926 5.44165 173.188 4.02748 172.693 2.80161C172.198 1.57699 171.043 0.777614 169.762 0.776367H163.402V8.03805H165.309V2.75921Z"
      fill="#000099"
    />
    <path
      d="M139.814 7.21441V5.52588H112.012C111.563 5.52588 111.199 5.90374 111.199 6.37014C111.199 6.83655 111.563 7.21441 112.012 7.21441H139.814Z"
      fill="#FF9900"
    />
  </svg>
</template>
