import { setupLayouts } from 'virtual:generated-layouts'
import { authGuard } from '@auth0/auth0-vue'
import type { NavigationGuardWithThis } from 'vue-router'

import generatedRoutes from '~pages'
import { Routes } from './types'
import { CypressLocalStorage } from '~cypress/types'

export const generateRoutes = () => {
  const routes = setupLayouts(generatedRoutes)
  const auth0 = JSON.parse(localStorage.getItem(CypressLocalStorage.AUTH0)!)

  if (!auth0?.body?.access_token) {
    routes.forEach((route) => {
      let beforeEnterRouteGuard: NavigationGuardWithThis<undefined> | undefined = authGuard

      // According to the docs, it is recommended that the redirect uri stays a public un-protected route
      // https://github.com/auth0/auth0-vue/blob/main/FAQ.md#4-getting-an-infinite-redirect-loop-between-my-application-and-auth0
      if (route.path === Routes.HOME)
        beforeEnterRouteGuard = undefined

      route.children?.forEach((childRoute) => {
        if (childRoute.meta?.noAuth) {
          // Reset auth guard for public routes
          beforeEnterRouteGuard = undefined
        }

        childRoute.beforeEnter = beforeEnterRouteGuard
      })
    })
  }

  return routes
}
