<script setup lang="ts">
import type { ComputedRef } from 'vue'

import { useUserCommunicationChannelData } from '~/composables/useUserCommunicationChannelData'
import { ContractEmailAddress } from '~/stores/user/types'
import { EmailCommunicationChannelsProps } from './types'

import CommunicationChannelListItem from '~/components/communication-channel-data/CommunicationChannelListItem/index.vue'

const props = defineProps({
  modelValue: null,
  registrationData: null,
  selectable: { type: Boolean, default: true }
})
const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()

const { emailCommunicationChannels } = useUserCommunicationChannelData()

const isRegistrationEmailPartOfCommunicationChannels: ComputedRef<boolean> = computed(() => {
  return !!props.registrationData?.email &&
    emailCommunicationChannels.value.some((item) => item.value === props.registrationData?.email)
})

const registrationEmailCommunicationChannel: ComputedRef<ContractEmailAddress | undefined> = computed(() => {
  if (!props.registrationData || isRegistrationEmailPartOfCommunicationChannels.value) return undefined

  return {
    id: props.registrationData.email,
    value: props.registrationData.email
  } as ContractEmailAddress
})

function handleSelectedItem(email: string) {
  emit('update:modelValue', email)
}

defineExpose({
  emailCommunicationChannels,
  handleSelectedItem
})
</script>

<template>
  <q-list
    :padding="false"
    class="email-communication-channels"
  >
    <template v-if="emailCommunicationChannels.length">
      <CommunicationChannelListItem
        v-for="item in emailCommunicationChannels"
        :key="item.value"
        :item="item"
        :clickable="emailCommunicationChannels.length > 1 || !!registrationEmailCommunicationChannel"
        :with-communication-type="emailCommunicationChannels.length > 1 || !!registrationEmailCommunicationChannel"
        action
        class="q-mb-lg"
        @click="handleSelectedItem"
      >
        <template
          v-if="selectable"
          #action
        >
          <input
            :checked="modelValue === item.value"
            :value="item.value"
            type="radio"
            name="communication-channel"
            class="custom-radio"
          >
        </template>
      </CommunicationChannelListItem>
    </template>

    <CommunicationChannelListItem
      v-if="registrationEmailCommunicationChannel"
      :item="registrationEmailCommunicationChannel"
      :contact-alt-caption="t('dialogs.email-communication-channels.registration-email-caption')"
      :clickable="!!emailCommunicationChannels.length"
      :with-communication-type="!!emailCommunicationChannels.length"
      action
      class="q-mb-lg"
      @click="handleSelectedItem"
    >
      <template
        v-if="selectable"
        #action
      >
        <input
          :checked="modelValue === registrationEmailCommunicationChannel.value"
          :value="registrationEmailCommunicationChannel.value"
          type="radio"
          name="communication-channel"
          class="custom-radio"
        >
      </template>
    </CommunicationChannelListItem>
  </q-list>
</template>

<style lang="scss" scoped>
.email-communication-channels.q-list {
  .q-item:last-child {
    margin-bottom: 16px !important;
  }
}
</style>
