<script setup lang="ts">
import { useQuasar } from 'quasar'

import { DIALOG_BREAKPOINT, useDialog } from '~/composables/dialogs/useDialog'
import { DialogId } from '~/composables/dialogs/useDialog/types'
import { useUserContractData } from '~/composables/useUserContractData'
import { EmailCommunicationChannelsDialogProps } from './types'
import TestIds from '~cypress/types/testIds'
import { usePaperlessCommunication } from '~/composables/usePaperlessCommunication'

import EDialog from '~/components/design-system/EDialog/index.vue'
import EmailCommunicationChannelsDialogContent from '~/components/paperless-communication/EmailCommunicationChannelsDialogContent.vue'

const { t } = useI18n()

defineProps({
  title: null,
  singleOption: { type: Boolean },
  primaryActionTitle: null,
  isProcessing: { type: Boolean }
})

const emit = defineEmits(['close', 'select'])

const $q = useQuasar()
const isDialogMobile = computed(() => $q.screen.width <= DIALOG_BREAKPOINT)

const { isOpen } = useDialog(DialogId.EMAIL_COMMUNICATION_CHANNELS_DIALOG)

usePaperlessCommunication(useUserContractData, t)

const dialogCloseHandler = async () => {
  emit('close')
}

const isSaving = ref(false)

function handleSelectedEmailCommunicationChannel(email: string) {
  emit('select', email)
}

defineExpose({
  t,
  TestIds,

  isOpen,
  dialogCloseHandler,

  isSaving,
  handleSelectedEmailCommunicationChannel
})
</script>

<template>
  <EDialog
    id="email-communication-channels-dialog"
    :data-testId="TestIds.EMAIL_COMMUNICATION_CHANNELS_DIALOG"
    :model-value="isOpen"
    desktop-width="650px"
    @close="dialogCloseHandler"
    :scroll-event="!isDialogMobile"
  >
    <template #default="{ isMobile, isMaximized }">
      <EmailCommunicationChannelsDialogContent
        :title="title"
        :mobile="isMobile"
        :maximized="isMaximized"
        :single-option="singleOption"
        :primary-action-title="primaryActionTitle"
        :is-processing="isProcessing"
        @select="handleSelectedEmailCommunicationChannel"
      />
    </template>
  </EDialog>
</template>

<style lang="scss">
#email-communication-channels-dialog {
  .q-dialog__inner--standard > div {
    @media (min-width: 699px) {
      height: 747px;
    }
  }
}
</style>
