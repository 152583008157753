import { App as VueApp } from 'vue'
import { createRouter, createWebHistory, isNavigationFailure, Router } from 'vue-router'
import { createHead } from '@vueuse/head'
import { Quasar, Notify } from 'quasar'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

import { deployConfig } from './utils/deployConfig'
import registerNotificationTypes from './utils/registerNotificationTypes'
import { generateRoutes } from './routing'
import useAppConfigStore from '~/stores/app-config'

import { DeployConfig } from './composables/useAppConfig'
import useReferrer from './composables/useReferrer'
import useUserStore from '~/stores/user'
import useTenantDefinedObservable from '~/composables/domain/useTenantDefinedObserver'
import useCookieConsent from './composables/useCookieConsent'
import { initialize as initializeGtm, useGoogleTagManager } from './composables/tracking/useGoogleTagManager'

import App from './App/index.vue'
import './styles/quasar/main.scss'
import './styles/cdc/main.scss'

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css'
import { PageTrackingParams } from './composables/tracking/useGoogleTagManager/types'
import { UserModule } from './types'

const initializeAppConfigInStore = (appConfig: DeployConfig) => {
  const appConfigStore = useAppConfigStore()

  appConfigStore.setAppConfig(appConfig)
}

const detectAndSaveReferrer = (router: Router) => {
  const { detectReferrerAndSaveToStore } = useReferrer()
  detectReferrerAndSaveToStore(router.currentRoute?.value?.query)
}

/**
 * Injects OneTrust Cookie Consent script based on the current route.
 *
 * @param {Router}router
 */
const injectOneTrustCookieScript = (router: Router) => {
  const { shouldInjectScript, injectScript } = useCookieConsent()
  if(shouldInjectScript(router.currentRoute?.value?.path)) {
    injectScript()
  }
}

/**
 * Initializes Google Tag Manager and sets up custom page view tracking.
 *
 * @param {Router}router
 * @param {DeployConfig} appConfig
 */
function handlePageViewTrackingWithGtm(router: Router, appConfig: DeployConfig) {
  initializeGtm(appConfig.GTM_ID)

  const { pushToDataLayer, requestPageViewTracking } = useGoogleTagManager(appConfig.GTM_ENV)

  // initialize environment and origin
  pushToDataLayer({ environment: appConfig.GTM_ENV })

  const { observe } = useTenantDefinedObservable()
  const userStore = useUserStore()
  observe(() => {
    const origin = userStore.registrationData?.lastUsedApplication?.name
    if (origin) pushToDataLayer({ origin })
  })

  // subscribe to route changes to request page view tracking
  // after each navigation
  router.afterEach((to) => {
    if (to.meta.tracking) {
      requestPageViewTracking(to, to.meta.tracking as PageTrackingParams)
    }
  })

  // request page view tracking for current route
  if (router.currentRoute.value?.meta.tracking) {
    requestPageViewTracking(router.currentRoute.value, router.currentRoute.value.meta.tracking as PageTrackingParams)
  }
}

function handleRouterReadyFailure(app: VueApp, error: any) {
  if (!isNavigationFailure(error)) {
    const injectedAppInsights = app.runWithContext<ApplicationInsights | undefined>(() => {
      return inject('appInsights') as ApplicationInsights
    })
    injectedAppInsights?.trackException({ exception: error as Error | undefined })
  }
}

(async () => {
  const appConfig = await deployConfig(fetch)

  const app = createApp(App)

  app.config.globalProperties.$deployConfig = appConfig

  const routes = generateRoutes()
  const router = createRouter({
    routes,
    history: createWebHistory(),
    scrollBehavior: () => ({ left: 0, top: 0 })
  })

  app.use(router)

  const head = createHead()
  app.use(head)

  Object.values(import.meta.glob<{ install: UserModule }>('./modules/**/index.ts', { eager: true }))
    .forEach(i => i.install?.({ app, router }))

  // when pinia module gets initialized it's best time to init app config
  initializeAppConfigInStore(appConfig as DeployConfig)

  app.use(Quasar, {
    plugins: {
      Notify
    }
  })
  registerNotificationTypes()

  app.mount('#app')

  router.isReady().then(() => {
    detectAndSaveReferrer(router)

    injectOneTrustCookieScript(router)

    handlePageViewTrackingWithGtm(router, appConfig as DeployConfig)
  }).catch(function (error) {
    // initial router push might be aborted, from auth guard for example,
    // so it's important to keep this catch to avoid "Uncaught (in promise)" errors

    handleRouterReadyFailure(app, error)
  })
})()
