<script lang="ts" setup>
import useUserRegistrationData from '~/composables/useUserRegistrationData'
import { GLOBAL_EVENTS_GROUP, useGlobalEvents } from '~/composables/utils/useGlobalEvents'
import { globalEventsListenerHelper } from './globalEvents'
import useDialogsStore from '~/stores/dialogs'
import useUserStore from '~/stores/user'
import { TenantId } from '~/composables/domain/useTenantConfig/types'

import SelectTenantDialog from '~/components/specific/SelectTenantDialog/index.vue'
import SenecTenantUnavailableDialog from '~/components/specific/SenecTenantUnavailableDialog/index.vue'
import PaperlessCommunicationModule from '~/components/paperless-communication/PaperlessCommunicationModule.vue'

onMounted(() => {
  useUserRegistrationData()
})

const { openSelectTenantDialog, closeSelectTenantDialog, openSenecTenantUnavailableDialog } = useDialogsStore()
const { setTenant } = useUserStore()

const eventBus = useGlobalEvents(GLOBAL_EVENTS_GROUP.USER)
function globalEventsListener(event: string) {
  globalEventsListenerHelper(event, openSelectTenantDialog, openSenecTenantUnavailableDialog)
}
eventBus.on(globalEventsListener)

function handleTenantSelected(tenantId: TenantId) {
  setTenant(tenantId)

  closeSelectTenantDialog()
}

onBeforeUnmount(() => {
  eventBus.off(globalEventsListener)
})
</script>

<template>
  <main class="main">
    <AppNavigationHeader />

    <div class="page-container page-container--responsive">
      <RouterView />
    </div>

    <AppFooter />

    <LogoutDialog />
    <SelectTenantDialog @tenant-selected="handleTenantSelected" />

    <SenecTenantUnavailableDialog />

    <PaperlessCommunicationModule />
  </main>
</template>
