<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

import useDialogsStore from '~/stores/dialogs'
import TestIds from '~cypress/types/testIds'
import { TRACKING_EVENTS } from '~/utils/constants/eventNames'

const { t } = useI18n()
const { closeLogoutDialog } = useDialogsStore()

const { logout: auth0Logout } = useAuth0()
const isLoading = ref(false)

const appInsights = inject('appInsights') as ApplicationInsights

function handleLogoutClick() {
  appInsights.trackEvent({ name: TRACKING_EVENTS.LOGOUT })

  isLoading.value = true
  auth0Logout({ logoutParams: { returnTo: window.location.origin } })
}
</script>

<template>
  <div class="logout-dialog column">
    <div class="logout-title t4">
      {{ t('dialogs.logout.title') }}
    </div>

    <DialogProfileInfo />

    <div class="column items-center justify-center relative-position">
      <EButton
        color="primary-blue"
        :text="t('dialogs.logout.button')"
        :on-button-click="handleLogoutClick"
        :is-loading="isLoading"
      />

      <TextButton
        :button-text="t('layout.buttons.abort')"
        :on-button-click="closeLogoutDialog"
        :data-testId="TestIds.LOGOUT_ABORT"
        class="q-mt-md q-mb-lg"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~vars';

.logout-dialog {
  .logout-title {
    color: $cod-gray;
    margin-bottom: 8px;
  }
}
</style>
