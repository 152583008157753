import type { QNotifyCreateOptions } from 'quasar'

import TestIds from '~cypress/types/testIds'

const nagivateNextIcon = '<span class="material-icons">navigate_next</span>'

export const getToastPosition = (
    isMobile: boolean,
    isNegativeToast: boolean,
    isMobilePositionTop: boolean
  ) => {
  if (isMobile) {
    return isMobilePositionTop || isNegativeToast ? 'top' : 'bottom'
  } else
    return 'top-right'
}

export const insertLink = (msg: string|undefined, actionText: string): string => {
  if (msg) {
    const lastDivIndex = msg.lastIndexOf('</div>')
    return `${msg.slice(0, lastDivIndex)}<div data-testId="${TestIds.TOAST_REFERRER_LINK}">${actionText}${nagivateNextIcon}</div>${msg.slice(lastDivIndex)}`
  } else
    return ''
}

export const getOptionsForToastWithReferrer = (msg: string, referrerURL: string): QNotifyCreateOptions => {
  return {
    actions: [
      { icon: 'close', color: 'white', round: true}
    ],
    timeout: 0,
    message: msg,
    attrs: {
      onClick: (e: MouseEvent) => {
        if ((e.target as HTMLElement).innerText !== 'close') {
          window.location.href = referrerURL
        }
      }
    },
    classes: 'toast-container cursor-pointer'
  }
}
