<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

import useDialogsStore from '~/stores/dialogs'
import TestIds from '~cypress/types/testIds'
import { TRACKING_EVENTS } from '~/utils/constants/eventNames'

const { t } = useI18n()
const { isLogoutPopupOpen } = toRefs(useDialogsStore())

const { logout } = useAuth0()
const isLoading = ref(false)

const appInsights = inject('appInsights') as ApplicationInsights

function handleLogoutClick () {
  appInsights.trackEvent({ name: TRACKING_EVENTS.LOGOUT })

  isLoading.value = true
  logout({ logoutParams: { returnTo: window.location.origin } })
}

defineExpose({
  TestIds,

  isLogoutPopupOpen,
  isLoading,
  handleLogoutClick
})
</script>

<template>
  <q-popup-proxy
    id="logout-popup"
    v-model="isLogoutPopupOpen"
    :offset="[303, 8]"
    breakpoint="140"
    no-parent-event
    :data-testId="TestIds.LOGOUT_POPUP"
  >
    <div class="container">
      <DialogProfileInfo is-medium />
      <EButton
        data-test="logout-button"
        outline
        size="small"
        color="primary-blue"
        :text="t('dialogs.logout.button')"
        :loading="isLoading"
        full-width
        class="btn-secondary"
        @click="handleLogoutClick()"
      />
    </div>
  </q-popup-proxy>
</template>

<style lang="scss">
@import '~vars';
@import '~utilities';

#logout-popup {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba($black, 0.08), 0px 0px 32px rgba($black, 0.06);
  min-height: 164px;
  outline: none;
  padding: 24px 32px;
  position: fixed;
  width: 335px;
  z-index: 10;

  @media (min-width: 1280px) {
    width: 348px;
  }
}
</style>
