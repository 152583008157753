<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EDialogContentTitle'
})
</script>

<script setup lang="ts">
import type { ComputedRef } from 'vue'

import { EDialogContentTitleProps } from './types'
import useDialogScroll from '~/composables/useDialogScroll'

const props = defineProps({
  title: null,
  dialogContentSelector: null,
  animated: { type: Boolean },
  mobile: { type: Boolean }
})

const attrs = useAttrs()

const titleElement: ComputedRef<HTMLElement | null> = computed(() => {
  if (!attrs.id) return null

  return document.querySelector(`#${attrs.id}`)
})

onMounted(() => {
  if (props.animated) {
    if (!attrs.id || !props.dialogContentSelector)
      throw new Error(
        'EDialogContentTitle: animated behavior requires \'id\' attribute and \'dialogContentSelector\' props'
      )

    const dialogElement = document.querySelector(props.dialogContentSelector)
    useDialogScroll(computed(() => props.title), titleElement, dialogElement)
  }
})
</script>

<template>
  <component
    :is="mobile ? 'h3' : 'h1'"
    :class="{ 't3': mobile, 't1': !mobile }"
  >
    {{ title }}
  </component>
</template>
