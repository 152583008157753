import { toValue, MaybeRefOrGetter } from 'vue'

import { UserBusinessSystems } from '~/middleware/api/useUserProfileData'
import { TenantId } from './../useTenantConfig/types'
import { TenantBusinessSystemsConfig } from './types'

const businessSystemsConfigMapDefault: Record<TenantId, TenantBusinessSystemsConfig> = {
  [TenantId.Yello]: {
    phonePin: { enabled: false },
    cmdm: { enabled: false }
  },

  [TenantId.Enbw]: {
    phonePin: { enabled: false },
    cmdm: { enabled: false }
  }
}

const businessSystemsConfigMap = reactive<Record<TenantId, TenantBusinessSystemsConfig>>(businessSystemsConfigMapDefault)

export interface BusinessSystemComposable {
  updateBusinessSystemsConfig(businessSystems: UserBusinessSystems): void
  resetBusinessSystemsConfig(): void

  isPhonePinEnabled: ComputedRef<boolean>
  hasCmdmEnabledOnAtLeastTwoTenants: ComputedRef<boolean>
}

export const useBusinessSystems = (tenantIdMaybeRefOrGetter: MaybeRefOrGetter<TenantId>): BusinessSystemComposable => {
  function updateBusinessSystemsConfig(businessSystems: UserBusinessSystems) {
    businessSystemsConfigMap[TenantId.Yello] = { ...businessSystemsConfigMap[TenantId.Yello], ...businessSystems[TenantId.Yello] }
    businessSystemsConfigMap[TenantId.Enbw] = { ...businessSystemsConfigMap[TenantId.Enbw], ...businessSystems[TenantId.Enbw] }
  }

  function resetBusinessSystemsConfig() {
    businessSystemsConfigMap[TenantId.Yello] = businessSystemsConfigMapDefault[TenantId.Yello]
    businessSystemsConfigMap[TenantId.Enbw] = businessSystemsConfigMapDefault[TenantId.Enbw]
  }

  /**
   * Checks if phone pin business system is enabled for the given tenant
   * @param {TenantId} tenantId
   *
   * @throws Will throw an error if the tenant is not supported.
   *
   * @returns {boolean}
   */
  function isPhonePinEnabledForTenant(tenantId: TenantId): boolean {
    const tenantBusinessSystemsConfig = businessSystemsConfigMap[tenantId]
    if (!tenantBusinessSystemsConfig) throw new Error(`Tenant ${tenantId} not found in business systems config`)

    return tenantBusinessSystemsConfig?.phonePin.enabled ?? false
  }

  const isPhonePinEnabled = computed(() => {
    const tenantId = toValue(tenantIdMaybeRefOrGetter)
    return tenantId && isPhonePinEnabledForTenant(tenantId)
  })

  const hasCmdmEnabledOnAtLeastTwoTenants = computed(() => {
    return Object.values(businessSystemsConfigMap).filter((tenantBusinessSystemsConfig) => tenantBusinessSystemsConfig.cmdm.enabled).length >= 2
  })

  return {
    updateBusinessSystemsConfig,
    resetBusinessSystemsConfig,

    isPhonePinEnabled,
    hasCmdmEnabledOnAtLeastTwoTenants
  }
}
