import { Screen } from 'quasar'
import useDialogsStore from '~/stores/dialogs'

const useDisplayLogout = () => {
  const { openLogoutDialog } = useDialogsStore()
  const { isLogoutPopupOpen } = toRefs(useDialogsStore())

  const isMaximized = Screen.width <= 635

  if (isMaximized) {
    openLogoutDialog()
  } else {
    isLogoutPopupOpen.value = !isLogoutPopupOpen.value
  }
}

export default useDisplayLogout
