import type { UseFetchReturn } from '@vueuse/core'
import type { ComputedRef } from 'vue'
import { useCookies } from '@vueuse/integrations/useCookies'
import type { QNotifyCreateOptions } from 'quasar'
import type { ComposerTranslation } from 'vue-i18n'

import useUserStore from '~/stores/user'
import { createPaperlessCommunication } from '~/middleware/api/paperless-communication'
import TestIds from '~cypress/types/testIds'
import { ToastNotificationType } from '~/utils/registerNotificationTypes/types'

export const FIRST_CHOICE_SKIP_COOKIE_NAME = 'paperlessCommunicationFirstChoiceSkip'
export const SECOND_CHOICE_SKIP_COOKIE_NAME = 'paperlessCommunicationSecondChoiceSkip'

export interface PaperlessCommunicationComposable {
  detectContractsWithPaperCommunication: () => boolean
  isPromptVisible: ComputedRef<boolean>
  isFirstChoiceSkip: ComputedRef<boolean>
  rememberFirstChoiceSkip: () => void
  isSecondChoiceSkip: ComputedRef<boolean>
  rememberSecondChoiceSkip: () => void
  optIn: (email: string) => UseFetchReturn<void>

  getNotificationOptedIn: () => QNotifyCreateOptions
  getNotificationFailed: () => QNotifyCreateOptions
  getNotificationPaperKept: () => QNotifyCreateOptions
}

export const usePaperlessCommunication = (
  useUserContractDataComposable: typeof import('~/composables/useUserContractData').useUserContractData,
  t: ComposerTranslation
): PaperlessCommunicationComposable => {
  const cookies = useCookies([FIRST_CHOICE_SKIP_COOKIE_NAME, SECOND_CHOICE_SKIP_COOKIE_NAME])

  const userStore = useUserStore()

  const {
    hasContractsWithPaperCommunication,
    contractsWithPaperCommunication
  } = useUserContractDataComposable()

  const idsOfContractsWithPaperCommunication = computed(() => contractsWithPaperCommunication.value.map(contract => contract.contractId))

  /**
   * Identifies if any of user contracts goes with paper communication.
   *
   * @returns {boolean}
   */
  function detectContractsWithPaperCommunication() {
    return hasContractsWithPaperCommunication.value
  }

  /**
   * Skip on first display gives a prompt to trigger the dialog again,
   * it is an indicator of that choice.
   * It is stored in cookies.
   *
   * @returns {ComputedRef<boolean>}
   */
  const isFirstChoiceSkip = computed(() => {
    const cookiesValue = cookies.get(FIRST_CHOICE_SKIP_COOKIE_NAME)
    return cookiesValue !== undefined
      && atob(cookiesValue) === idsOfContractsWithPaperCommunication.value.join(',')
  })

  function rememberFirstChoiceSkip() {
    const contractIdsBase64 = btoa(idsOfContractsWithPaperCommunication.value.join(','))
    cookies.set(FIRST_CHOICE_SKIP_COOKIE_NAME, contractIdsBase64)
  }

  /**
   * Skip action on prompt should lead to paperless communication dialog be completely ignored,
   * it is an indicator of that choice.
   * It is stored in cookies.
   *
   * @returns {ComputedRef<boolean>}
   */
  const isSecondChoiceSkip = computed(() => {
    const cookiesValue = cookies.get(SECOND_CHOICE_SKIP_COOKIE_NAME)
    return cookiesValue !== undefined
      && atob(cookiesValue) === idsOfContractsWithPaperCommunication.value.join(',')
  })

  function rememberSecondChoiceSkip() {
    const contractIdsBase64 = btoa(idsOfContractsWithPaperCommunication.value.join(','))
    cookies.set(SECOND_CHOICE_SKIP_COOKIE_NAME, contractIdsBase64)
  }

  const isPromptVisible = computed(() =>
    detectContractsWithPaperCommunication()
    && isFirstChoiceSkip.value
    && !isSecondChoiceSkip.value
  )

  function optIn(email: string) {
    const options = { tenant: userStore.tenantId }

    return createPaperlessCommunication(email, idsOfContractsWithPaperCommunication.value, options)
  }

  function getNotificationOptedIn(): QNotifyCreateOptions {
    return {
      message: `
        <div data-testId="${TestIds.PAPERLESS_COMMUNICATION_DIALOG_SUCCESS_TOAST}" class="toast-content">
          <div class="cdc-fw-500">${t('paperless-communication.notifications.chosen')}</div>
        </div>`,
      type: ToastNotificationType.POSITIVE
    }
  }

  function getNotificationFailed(): QNotifyCreateOptions {
    return {
      message: `
        <div data-testId="${TestIds.PAPERLESS_COMMUNICATION_DIALOG_FAILED_TOAST}" class="toast-content">
          <div class="cdc-fw-500">${t('paperless-communication.notifications.failed')}</div>
        </div>`,
      type: ToastNotificationType.NEGATIVE
    }
  }

  function getNotificationPaperKept(): QNotifyCreateOptions {
    return {
      message: `
        <div data-testId="${TestIds.PAPERLESS_COMMUNICATION_DIALOG_PAPER_KEPT_TOAST}" class="toast-content">
          <div class="cdc-fw-500">${t('paperless-communication.notifications.paper-kept')}</div>
        </div>`,
      type: ToastNotificationType.POSITIVE
    }
  }

  return {
    detectContractsWithPaperCommunication,

    isPromptVisible,
    isFirstChoiceSkip,
    rememberFirstChoiceSkip,
    isSecondChoiceSkip,
    rememberSecondChoiceSkip,

    optIn,

    getNotificationOptedIn,
    getNotificationFailed,
    getNotificationPaperKept
  }
}
