export const TRACKING_EVENTS = {
  CHANGE_PHONE_NUMBER: 'changePhoneNumber',
  SAVE_PHONE_FAILED: 'savePhoneFailed',
  CHANGE_PHONE_FAILED: 'changePhoneFailed',
  CHANGE_PHONE_PARTIALLY_FAILED: 'changePhonePartiallyFailed',
  CHANGE_EMAIL: 'changeEmail',
  PHONE_NUMBER_CHANGED: 'phoneNumberChanged',
  SAVE_PIN_FAILED: 'savePinFailed',
  SHOW_PIN_NOT_PROVIDED: 'showPinNotProvided',
  SHOW_PIN_FAILED: 'showPinFailed',
  SHOW_PIN_SUCCESS: 'showPinSuccess',
  CHANGE_PIN_STARTED: 'changePinStarted',
  CHANGE_PIN_SUCCESS: 'changePinSuccess',
  CHANGE_PIN_FAILED: 'changePinFailed',
  CHANGE_LOGIN_EMAIL: 'changeLoginEmail',
  CHANGE_PASSWORD: 'changePassword',
  CHANGE_PASSWORD_RETRY: 'changePasswordRetry',
  LOGOUT: 'logout',
  PERMISSION_TOGGLE: 'permissionToggle',
  PAPERLESS_CONTRACT: 'switchToPaperlessContract'
}
