enum ContractsPaymentsTestIds {
  CONTRACTS_PAYMENTS_CONTENT = 'contractsPaymentsContent',
  CONTRACTS_PAYMENTS_CONTRACT_CONTENT = 'contractsPaymentsContractContent',
  CONTRACTS_PAYMENTS_CONTRACT_NUMBER = 'contractsPaymentsContractNumber',
  CONTRACTS_PAYMENTS_CONTRACT_TARIFF = 'contractsPaymentsContractTariff',
  CONTRACTS_PAYMENTS_CONTRACT_DURATION = 'contractsPaymentsContractDuration',
  CONTRACTS_PAYMENTS_CONTRACT_PAYMENT_TYPE = 'contractsPaymentsContractPaymentType',
  CONTRACTS_PAYMENTS_MANAGE_CONTRACT = 'contractsPaymentsManageContract',
  CONTRACTS_PAYMENTS_CANCEL_CONTRACT = 'contractsPaymentsCancelContract',
  CONTRACTS_PAYMENTS_CONTRACTS_NOT_FOUND_TEXT = 'contractsPaymentsNotFoundText',

  CONTRACTS_PAYMENTS_PAYMENT_TYPE_CONTRACT_BANK_ACCOUNT = 'personalDataPaymentTypeContractBankAccount',
  CONTRACTS_PAYMENTS_PAYMENT_TYPE_SELF_BANK_ACCOUNT = 'personalDataPaymentTypeSelfBankAccount',
  CONTRACTS_PAYMENTS_PAYMENT_TYPE_SELF_PAYMENT = 'personalDataPaymentTypeSelfPayment',
  CONTRACTS_PAYMENTS_NO_PAYMENT_INFO_CARD = 'personalDataNoPaymentInfoCard',
  CONTRACTS_PAYMENTS_PAYMENT_LOADING_AND_NOT_FOUND_TEXT = 'personalDataLoadingAndNotFoundText',
  CONTRACTS_PAYMENTS_SELF_PAYMENT = 'personalDataSelfPayment',
  CONTRACTS_PAYMENTS_SELF_PAYMENT_INFO_CARD = 'personalDataSelfPaymentInfoCard'
}

export default ContractsPaymentsTestIds
