import type { ComputedRef, Ref } from 'vue'

import useDialogsStore from '~/stores/dialogs'

const useDialogScroll = (
  dialogHeading: ComputedRef<string> | Ref<string>,
  titleEl: ComputedRef<HTMLElement | null>,
  dialogContentEl: Element | null
) => {
  const dialogStore = useDialogsStore()

  const handleScroll = () => {
    if (!dialogContentEl || !titleEl.value) return

    const currentScrollTop = dialogContentEl.scrollTop
    const MAX_THRESHOLD = titleEl.value.offsetHeight

    const shouldSetDialogHeading = currentScrollTop > MAX_THRESHOLD && !dialogStore.dialogHeading
    const shouldResetDialogHeading = currentScrollTop < MAX_THRESHOLD && dialogStore.dialogHeading

    if (shouldSetDialogHeading) {
      dialogStore.setDialogHeading(dialogHeading.value)
    } else if (shouldResetDialogHeading) {
      dialogStore.setDialogHeading('')
    }
  }

  const addScrollListener = () => {
    if (dialogContentEl && titleEl.value) {
      dialogContentEl.addEventListener('scroll', handleScroll)
    }
  }

  const removeScrollListener = () => {
    if (dialogContentEl && titleEl.value) {
      dialogContentEl.removeEventListener('scroll', handleScroll)
      dialogContentEl.scrollTop = 0
    }
  }

  watch(titleEl, () => {
    removeScrollListener()
    dialogStore.setDialogHeading('')
    addScrollListener()
  })

  onUnmounted(() => {
    removeScrollListener()
  })

  addScrollListener()
}

export default useDialogScroll
