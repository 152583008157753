<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SenecTenantUnavailableDialog'
})
</script>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

import useDialogsStore from '~/stores/dialogs'
import TestIds from '~cypress/types/testIds'

import EDialog from '~/components/design-system/EDialog/index.vue'
import SenecTenantUnavailableDialogContent from './SenecTenantUnavailableDialogContent.vue'

const { isSenecUnavailableDialogOpen } = storeToRefs(useDialogsStore())

</script>

<template>
  <EDialog
    :data-testId="TestIds.TENANT_SENEC_UNAVAILABLE"
    v-model="isSenecUnavailableDialogOpen"
    :toolbar="false"
    persistent
    desktop-width="650px"
    class="senec-tenant-unavailable-dialog"
  >
    <template #default="{ isMobile, isMaximized }">
      <SenecTenantUnavailableDialogContent
        :mobile="isMobile"
        :maximized="isMaximized"
      />
    </template>
  </EDialog>
</template>
