<script setup lang="ts">
import TestIds from '~cypress/types/testIds'
import { NavTab, NavTabsProps } from './types'
import { Routes } from '~/routing/types'

const { t } = useI18n()
defineProps({
  onTabClick: { type: Function }
})

const tabs: NavTab[] = [
  {
    label: t('layout.nav-tabs.overview'),
    link: Routes.OVERVIEW,
    dataTestId: TestIds.TAB_OVERVIEW,
    onClick: __props.onTabClick
  },
  {
    label: t('layout.nav-tabs.personal-data'),
    link: Routes.PERSONAL_DATA,
    dataTestId: TestIds.TAB_PERSONAL_DATA,
    onClick: __props.onTabClick
  },
  {
    label: t('layout.nav-tabs.contracts-payments'),
    link: Routes.CONTRACTS_PAYMENTS,
    dataTestId: TestIds.TAB_CONTRACTS_PAYMENTS,
    onClick: __props.onTabClick
  },
  {
    label: `${t('layout.nav-tabs.registration-security')}`,
    link: Routes.SECURITY,
    dataTestId: TestIds.TAB_REGISTRATION,
    onClick: __props.onTabClick
  },
  {
    label: `${t('layout.nav-tabs.data-privacy')}`,
    link: Routes.PRIVACY,
    dataTestId: TestIds.TAB_PRIVACY,
    onClick: __props.onTabClick
  }
]
</script>

<template>
  <div
    class="tabs-container"
    :data-testId="TestIds.NAV_TABS"
  >
    <ETabs :tabs="tabs" />
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 854px) {
  .tabs-container {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.12);
  }
}

@media (max-width: 698px) {
  .tabs-container::v-deep div.q-tab:last-child {
    margin-right: 0;
    padding-right: 16px;
  }
}
</style>
