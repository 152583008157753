<script setup lang="ts">
import { ETabProps } from './types'

defineProps({
  tabs: null
})

const route = useRoute()

/** Get the first node of the path (the path parent) */
const extractLink = (path: string|undefined) => path ? `/${path?.split('/')[1]}` : ''

const tabLink = ref(extractLink(route.path))

const router = useRouter()
const goTo = (link: string): void => {
  tabLink.value = link
  router.push(link)
}

const changeTabLinkOnRouteChange = (newRoutePath: string) => {
  const newRouteLink = extractLink(newRoutePath)
  if (newRouteLink && newRouteLink !== tabLink.value) {
    tabLink.value = newRouteLink
  }
}

watch(() => route.path, changeTabLinkOnRouteChange)
</script>

<template>
  <q-tabs
    v-model="tabLink"
    class="e-tabs"
    active-color="primary-blue"
    indicator-color="primary-blue"
    :outside-arrows="false"
    :right-icon="'none'"
    :left-icon="'none'"
  >
    <q-tab
      v-for="(tab, index) in tabs"
      :key="index"
      :name="tab.link"
      :label="tab.label"
      :data-testId="tab.dataTestId"
      :ripple="false"
      @click="goTo(tab.link)"
    />
  </q-tabs>
</template>

<style lang="scss">
@import '~vars';
@import '~utilities';

.e-tabs {
  color: $tapa;
  height: 100%;

  @media (max-width: 854px) {
    height: 40px;
    margin-top: 12px;
  }

  .q-tabs__content {
    height: 100%;
    justify-content: space-between;

    @media (min-width: 650px) and (max-width: 854px) {
      justify-content: center;
    }
  }

  .q-tab {
    font-family: $font-mark;
    height: 100%;
    margin: 0 16px;
    @media (min-width: 650px) and (max-width: 854px) {
      margin: 0 28px;
    }
    min-height: 37px;
    padding: 0;
    text-transform: none;

    .q-tab__label {
      @extend .font-mark;
      @extend .cdc-fw-500;
      font-size: 14px;
      letter-spacing: 0em;
      line-height: 2.666;

      @media (min-width: 768px) {
        font-size: 15px;
      }

      @media (min-width: 1280px) {
        font-size: 16px;
      }
    }
  }

  .q-focusable:focus, .q-hoverable:hover {
    color: $primary-blue;

    > .q-focus-helper {
      background: transparent !important;
      opacity: 0 !important;

      &:before {
        opacity: 0 !important;
      }
      &:after {
        opacity: 0 !important;
      }
    }
  }
}
</style>
