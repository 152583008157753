enum RegistrationTestIds {
  REGISTRATION_CONTENT = 'registrationContent',
  REGISTRATION_EMAIL_FIELD = 'registrationEmailField',
  REGISTRATION_PASSWORD_FIELD = 'registrationPasswordField',
  REGISTRATION_PIN_FIELD = 'registrationPinField',
  REGISTRATION_PASSWORD_BUTTON = 'registrationPasswordButton',
  REGISTRATION_RETRY_PASSWORD_BUTTON = 'registrationRetryPasswordButton',
  REGISTRATION_RETRY_PASSWORD_MOBILE_BUTTON = 'registrationRetryPasswordMobileButton',
  REGISTRATION_RETRY_PASSWORD_TIMER = 'registrationRetryPasswordTimer',
  REGISTRATION_EMAIL_RESET_BUTTON = 'registrationEmailResetButton',
  REGISTRATION_PIN_RESET_BUTTON = 'registrationPinResetButton',
  REGISTRATION_PIN_VIEW_HIDE_BUTTON = 'registrationPinViewHideButton',
  EDIT_PHONE_PIN_DIALOG = 'editPhonePinDialog',
  EDIT_PHONE_PIN_FIELD = 'editPhonePinField',
  EDIT_PHONE_PIN_SUBMIT = 'editPhonePinSubmit',
  EDIT_PHONE_PIN_SUCCESS_TOAST = 'editPhonePinSuccessToast',
  EDIT_PHONE_PIN_ERROR_TOAST = 'editPhonePinErrorToast',
}

export default RegistrationTestIds
