import type { LocationQuery } from 'vue-router'
import { isAllowedSite } from './index'

const detectReferrerURL = (query:LocationQuery, referringSites: string[]):string => {
  const referrerURLBase64 = String(query?.referrer ?? '')
  if(referrerURLBase64){
    const referrerURL = atob(referrerURLBase64)
    const isAllowed = isAllowedSite(referrerURL, referringSites)
    if(isAllowed) {
      return referrerURL
    }
  }
  return ''
}

export default detectReferrerURL
