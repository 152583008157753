import { Notify, Screen } from 'quasar'
import { ToastNotificationType } from './types'

const DIALOG_BREAKPOINT = 698
const TOAST_DEFAULT_TIMEOUT = 4000

const registerNotificationTypes = () => {
  const isMobile = Screen.width <= DIALOG_BREAKPOINT
  const position = isMobile ? 'bottom' : 'top-right'

  Notify.registerType(ToastNotificationType.POSITIVE, {
    icon: 'check_circle',
    html: true,
    color: 'positive',
    textColor: 'white',
    position,
    classes: 'toast-container',
    timeout: TOAST_DEFAULT_TIMEOUT,
    badgeColor: 'white',
    badgeTextColor: 'positive',
    group: false
  })

  Notify.registerType(ToastNotificationType.PARTIAL_POSITIVE, {
    icon: 'error',
    html: true,
    color: 'positive',
    textColor: 'white',
    position,
    classes: 'toast-container',
    timeout: TOAST_DEFAULT_TIMEOUT,
    badgeColor: 'white',
    badgeTextColor: 'positive',
    group: false
  })

  Notify.registerType(ToastNotificationType.NEGATIVE, {
    icon: 'error',
    html: true,
    color: 'negative',
    textColor: 'white',
    position,
    classes: 'toast-container',
    timeout: TOAST_DEFAULT_TIMEOUT,
    badgeColor: 'white',
    badgeTextColor: 'negative',
    group: false
  })

}

export default registerNotificationTypes
