<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DialogProfileInfo'
})
</script>

<script setup lang="ts">
import TestIds from '~cypress/types/testIds'
import { DialogProfileInfoProps } from './types'
import { ESkeletonTypes } from '~/components/design-system/ESkeleton/types'
import useUserRegistrationDataDefault from '~/composables/useUserRegistrationData'
import useUserPersonalData from '~/composables/useUserPersonalData'
import useTenantDefinedObservable from '~/composables/domain/useTenantDefinedObserver'
import useUserStore from '~/stores/user'

defineProps({
  isMedium: { type: Boolean }
})

const name = ref('')
const email = ref('')

const hasFullData = computed(() => name.value && email.value)

const userStore = useUserStore()
const profilePictureContent = computed(() => userStore.customerProfileData?.personalData?.avatarContent)

const isLoading = ref(true)

const handleDataFetching = async () => {
  const registrationData = await useUserRegistrationDataDefault()
  if (!registrationData || !userStore.tenantId) return

  const { personalData } = await useUserPersonalData()

  isLoading.value = false

  name.value = personalData.name
  email.value = registrationData.email
}

onMounted(handleDataFetching)

const onTenantLoaded = () => {
  handleDataFetching()
}
const { observe } = useTenantDefinedObservable()
const unobserve = observe(onTenantLoaded)

onBeforeUnmount(() => {
  unobserve()
})
</script>

<template>
  <div
    class="profile-container text-gray-1 row no-wrap"
    :class="{ 'is-medium': isMedium, 'q-pt-md': !isMedium }"
  >
    <template v-if="isLoading">
      <ESkeleton
        :type="ESkeletonTypes.AVATAR"
        size="48px"
        class="q-mr-md"
      />
      <div>
        <ESkeleton
          :type="ESkeletonTypes.RECT"
          width="134px"
          height="24px"
          class="q-mb-xs"
        />
        <ESkeleton
          :type="ESkeletonTypes.RECT"
          width="181px"
          height="18px"
        />
      </div>
    </template>
    <template v-else>
      <ProfilePicture
        :avatar-content="profilePictureContent"
        :is-medium="isMedium"
        :is-minified="!isMedium"
        :data-testId="TestIds.LOGOUT_DIALOG_PROFILE_PICTURE"
      />

      <div
        class="q-ml-md profile-content"
        :class="{ 'singular-content': !hasFullData }"
      >
        <div
          class="profile-name t5"
          :data-testId="TestIds.LOGOUT_DIALOG_NAME"
          v-if="name"
        >
          {{ name }}
        </div>

        <div
          class="profile-email s1"
          v-if="email"
        >
          {{ email }}
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import '~vars';

.profile-container {
  margin-bottom: 40px;

  .profile-name {
    color: $dune;
    margin-bottom: 7px;
    word-break: break-word;
  }

  .profile-email {
    color: $tapa;
    word-break: break-word;
  }

  .profile-content {
    width: 85%;
  }

  &.is-medium {
    margin-bottom: 24px;
    .profile-content {
      width: auto;
    }
  }
}

.singular-content {
  align-items: center;
  display: flex;
}
</style>
