<script setup lang="ts">
import has from 'lodash/has'

import { ContractEmailAddress } from '~/stores/user/types'
import { CommunicationChannelListItemProps } from './types'

defineEmits(['click'])

const { t } = useI18n()

const props = defineProps({
  item: null,
  action: { type: Boolean },
  withCommunicationType: { type: Boolean, default: true },
  contactAltCaption: null,
  clickable: { type: Boolean, default: true }
})

const isContact = computed(() => !(has(props.item, 'contracts') && (props.item as ContractEmailAddress).contracts.length))

const contactCaption = computed(() => props.contactAltCaption ?? t('dialogs.email-communication-channels.contact-caption'))

const contractCaption = computed(() => {
  const contractCount = (props.item as ContractEmailAddress)?.contracts?.length
  return t('dialogs.email-communication-channels.contract-caption', [contractCount])
})

const caption = computed(() => isContact.value
  ? contactCaption.value
  : contractCaption.value
)

defineExpose({
  t,

  caption
})
</script>

<template>
  <q-item
    dense
    :clickable="clickable"
    class="email-communication-channel"
    @click="$emit('click', item.value)"
  >
    <q-item-section>
      <q-item-label class="t5-clean text-dune">
        {{ item.value }}
      </q-item-label>
      <q-item-label
        v-if="withCommunicationType"
        class="email-communication-channel__communicationType s1"
        :class="[isContact ? 'text-tapa' : 'text-primary']"
      >
        {{ caption }}
      </q-item-label>
    </q-item-section>

    <q-item-section
      v-if="action"
      side
    >
      <slot name="action" />
    </q-item-section>
  </q-item>
</template>

<style lang="scss">
.email-communication-channel.q-item {
  &.q-item--dense {
    padding: 0;
  }

  &.q-item--clickable:hover,
  &.q-item--clickable:focus {
    & > .q-focus-helper {
      background: transparent;
    }
  }
}
</style>
