import { Auth0Plugin, createAuth0 } from '@auth0/auth0-vue'

import { DeployConfig } from '~/composables/useAppConfig'

let auth0Plugin: Auth0Plugin

const useAuth = (appConfig: DeployConfig): Auth0Plugin => {
  if (!auth0Plugin) {
    auth0Plugin = createAuth0({
      domain: appConfig.AUTH0_DOMAIN,
      clientId: appConfig.AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: appConfig.AUTH0_AUDIENCE,
        scope: appConfig.AUTH0_SCOPE
      }
    })
  }

  return auth0Plugin
}

export default useAuth
