<script lang="ts">
import { defineComponent } from 'vue'
import { TenantId } from '~/composables/domain/useTenantConfig/types'

export default defineComponent({
  name: 'SelectTenantDialog'
})
</script>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

import useDialogsStore from '~/stores/dialogs'
import TestIds from '~cypress/types/testIds'

import EDialog from '~/components/design-system/EDialog/index.vue'
import SelectTenantDialogContent from './SelectTenantDialogContent.vue'

const emit = defineEmits(['tenant-selected'])

const { isSelectTenantDialogOpen } = storeToRefs(useDialogsStore())
const { closeSelectTenantDialog } = useDialogsStore()

const handleTenantOptionClick = (tenantId: TenantId) => {
  emit('tenant-selected', tenantId)
}
</script>

<template>
  <EDialog
    :data-testId="TestIds.TENANT_SELECTION_DIALOG"
    v-model="isSelectTenantDialogOpen"
    :toolbar="false"
    persistent
    class="select-tenant-dialog"
    @close="closeSelectTenantDialog"
  >
    <template #default="{ isMobile, isMaximized }">
      <SelectTenantDialogContent
        :mobile="isMobile"
        :maximized="isMaximized"
        @click:tenant-option="handleTenantOptionClick"
      />
    </template>
  </EDialog>
</template>
