enum PrivacyTestIds {
  PRIVACY_CONTENT = 'privacyContent',
  COOKIE_CONSENT_SECTION = 'cookieConsentSection',
  DELETE_MEK_SECTION = 'deleteMEKSection',
  DELETE_MEK_BUTTON_LINK = 'deleteMEKButtonLink',
  PROFILE_DELETION_DIALOG = 'profileDeletionDialog',
  OPEN_PROFILE_DELETION_DIALOG_BTN = 'openProfileDeletionDialogBtn',
  CLOSE_PROFILE_DELETION_DIALOG_BTN = 'closeProfileDeletionDialogBtn',
  DELETE_MEK_ACCOUNT_BTN = 'deleteMekAccountBtn',
  DELETE_MEK_ACCOUNT_ERROR_TOAST = 'deleteMekAccountErrorToast',
  DELETE_MEK_ACCOUNT_SUCCESS = 'deleteMekAccountSuccess',
  PRIVACY_ECO_PERMISSIONS_TILE = 'privacyEcoPermissionsTile',
  PRIVACY_EDIT_ECO_PERMISSIONS_SUCCESS_TOAST = 'privacyEditEcoPermissionsSuccessToast',
  PRIVACY_EDIT_ECO_PERMISSIONS_ERROR_TOAST = 'privacyEditEcoPermissionsErrorToast',
  PRIVACY_EDIT_ECO_PERMISSIONS_FORM_CONFIRM = 'privacyEditEcoPermissionsFormConfirm',
  PRIVACY_EDIT_ECO_PERMISSIONS_FORM_ACTIVATE_ALL = 'privacyEditEcoPermissionsFormActivateAll',
  PRIVACY_EDIT_ECO_PERMISSIONS_FORM_DEACTIVATE_ALL = 'privacyEditEcoPermissionsFormDeactivateAll',
  PRIVACY_EDIT_ECO_PERMISSIONS_DIALOG = 'privacyEditEcoPermissionsDialog',
  PRIVACY_EDIT_ECO_PERMISSIONS_GCM_MORE_INFO_CTA = 'privacyEditEcoPermissionsGCMMoreInfoCta',
  PRIVACY_ADV_PERMISSIONS_TILE = 'privacyAdvPermissionsTile',
  PRIVACY_EDIT_ADV_PERMISSIONS_SUCCESS_TOAST = 'privacyEditAdvPermissionsSuccessToast',
  PRIVACY_EDIT_ADV_PERMISSIONS_ERROR_TOAST = 'privacyEditAdvPermissionsErrorToast',
  PRIVACY_EDIT_ADV_PERMISSIONS_FORM_SUBMIT = 'privacyEditAdvPermissionsFormSubmit',
  PRIVACY_EDIT_ADV_PERMISSIONS_DIALOG = 'privacyEditAdvPermissionsDialog',
  PRIVACY_EDIT_ADV_PERMISSIONS_GCM_MORE_INFO_CTA = 'privacyEditAdvPermissionsGCMMoreInfoCta',
  PRIVACY_PERMISSIONS_PRODUCT_CONSENT_EDIT_CTA = 'privacyPermissionsRevokeProductConsentEditCta',
  PRIVACY_PERMISSIONS_REVOKE_PRODUCT_CONSENT_DIALOG = 'privacyPermissionsRevokeProductConsentDialog',
  PRIVACY_PERMISSIONS_PRODUCT_CONSENT_REVOCATION_CONFIRM = 'privacyPermissionsRevokeProductConsentRevocationConfirm',
  PRIVACY_PERMISSIONS_REVOKE_PRODUCT_CONSENT_SUCCESS_TOAST = 'privacyPermissionsRevokeProductConsentSuccessToast',
  PRIVACY_TRACKING_PERMISSIONS_TILE = 'privacyTrackingPermissionsTile',
  PRIVACY_EDIT_TRACKING_PERMISSIONS_DIALOG = 'privacyEditTrackingPermissionsDialog',
  PRIVACY_EDIT_TRACKING_PERMISSIONS_FORM_SUBMIT = 'privacyEditTrackingPermissionsFormSubmit',
  PRIVACY_EDIT_TRACKING_PERMISSIONS_SUCCESS_TOAST = 'privacyEditTrackingPermissionsSuccessToast',
  PRIVACY_EDIT_TRACKING_PERMISSIONS_ERROR_TOAST = 'privacyEditTrackingPermissionsErrorToast',
  PRIVACY_EDIT_TRACKING_PERMISSIONS_NESTED_DIALOG = 'privacyEditTrackingPermissionsNestedDialog',
  PRIVACY_ECO_PERMISSIONS_OFFERS_NESTED_DIALOG_CTA = 'privacyEcoPermissionsOffersNestedDialogCta',
  PRIVACY_ECO_PERMISSIONS_OFFERS_NESTED_DIALOG = 'privacyEcoPermissionsOffersNestedDialog',
  PRIVACY_PERMISSIONS_GCM_INFORMATION_DIALOG = 'privacyPermissionsGCMInformationDialog',
  PARTNER_INFORMATION_DIALOG = 'partnerInformationDialog',
  PARTNER_INFORMATION_DIALOG_CTA = 'partnerInformationDialogCta',
  INTEREST_PROFILE_DIALOG_CTA = 'interestProfileDialogCta',
  INTEREST_PROFILE_DIALOG = 'interestProfileDialog',
  REVOCATION_INFORMATION_DIALOG_CTA = 'revocationInformationDialogCta',
  REVOCATION_INFORMATION_DIALOG = 'revocationInformationDialog'
}

export default PrivacyTestIds
