export const GaSnippet = {
  GTM: `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM_ID');`,
  GTM_DATA_LAYER: 'window.dataLayer = window.dataLayer || [];'
}

export enum PageTrackingMode {
  DEFAULT = 'default',
  IMPERSONATE_ROUTE_PARAMS = 'impersonate_route_params'
}

export interface PageTrackingImpersonationParams {
  [key: string]: string
}

export interface PageTrackingParams {
  mode: PageTrackingMode
  impersonation?: PageTrackingImpersonationParams
}

export const GA_CUSTOM_PAGE_VIEW_EVENT_NAME = 'navigateToPath'
