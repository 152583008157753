import { defineStore } from 'pinia'

import { DeployConfig } from '~/composables/useAppConfig'
import { StoreKeys } from '~/stores/types'
import AppConfigState from './types'

interface AppConfigActions {
  setAppConfig: (data: DeployConfig) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useAppConfigStore = defineStore<StoreKeys, AppConfigState, any, AppConfigActions>(StoreKeys.APP_CONFIG, {
  state: (): AppConfigState => ({
    data: {
      APPLICATIONINSIGHTS_CONNECTION_STRING: '',
      APIM_URL: '',
      BFF_API: '',
      AUTH0_DOMAIN: '',
      AUTH0_CLIENT_ID: '',
      AUTH0_AUDIENCE: '',
      AUTH0_SCOPE: '',
      BFF_SUBSCRIPTION_KEY: '',
      IS_AUTH0_MOCKED: '',
      IS_CMDM_MOCKED: '',
      MOCK_DATA_PERSONA: '',
      YELLO_PORTAL_URL: '',
      ENBW_PORTAL_URL: '',
      SENEC_PORTAL_URL: '',
      YELLO_CONTRACT_MANAGEMENT_URL: '',
      ENBW_CONTRACT_MANAGEMENT_URL: '',
      REFERRING_TOUCHPOINTS: '',
      ONE_TRUST_COOKIE_DOMAIN: '',
      GTM_ID: '',
      GTM_ENV: ''
    }
  }),

  getters: {
    appConfig: (state: AppConfigState) => state.data
  },

  actions: {
    setAppConfig(appConfig: DeployConfig) {
      this.data = appConfig
    }
  }
})

export default useAppConfigStore
