<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ESkeleton'
})
</script>

<script setup lang="ts">
import { ESkeletonProps } from './types'

defineProps({
  type: null,
  size: null,
  width: null,
  height: null
})
</script>

<template>
  <q-skeleton
    :type="type"
    :size="size"
    :height="height"
    :width="width"
  />
</template>
