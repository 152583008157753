<script setup lang="ts">
import { EmailCommunicationChannelsDialogContentProps } from './types'
import TestIds from '~cypress/types/testIds'

import { useUserRegistrationData } from '~/composables/useUserRegistrationData'

import EDialogContentTitle from '~/components/EDialogContentTitle/index.vue'
import EmailCommunicationChannels from '~/components/communication-channel-data/EmailCommunicationChannels.vue'

const emit = defineEmits(['close', 'select'])

const props = defineProps({
  mobile: { type: Boolean },
  maximized: { type: Boolean },
  title: null,
  singleOption: { type: Boolean },
  primaryActionTitle: null,
  isProcessing: { type: Boolean }
})

const { registrationData } = useUserRegistrationData()

const selectedCommunicationChannel = ref<string | undefined>(undefined)

onMounted(() => {
  // select registration email by default when single option is available
  if (props.singleOption && registrationData.value) {
    selectedCommunicationChannel.value = registrationData.value.email
  }
})

defineExpose({
  TestIds,

  registrationData,
  selectedCommunicationChannel
})
</script>

<template>
  <div
    id="email-communication-channels-dialog-content"
    class="full-height email-communication-channels-dialog-content"
  >
    <q-card-section>
      <EDialogContentTitle
        id="email-communication-channels-dialog-content-title"
        dialog-content-selector="#email-communication-channels-dialog-content"
        :title="title"
        :animated="!mobile"
        :mobile="mobile"
        class="q-mb-lg"
      />

      <EmailCommunicationChannels
        v-model="selectedCommunicationChannel"
        :registration-data="registrationData"
        :selectable="!singleOption"
      />
    </q-card-section>

    <q-card-actions>
      <EButton
        v-if="selectedCommunicationChannel"
        :data-testId="TestIds.EMAIL_COMMUNICATION_CHANNELS_DIALOG_SELECT_CTA"
        data-test="email-communication-channel-select-action"
        color="primary-blue"
        :text="primaryActionTitle"
        full-width
        :is-loading="isProcessing"
        :class="{ 'q-mb-lg': mobile }"
        @click="emit('select', selectedCommunicationChannel)"
      />
    </q-card-actions>
  </div>
</template>

<style lang="scss" scoped>
@import '~vars';

.email-communication-channels-dialog-content {
  .q-card__section {
    h3, .t5 {
      margin-bottom: 24px;
    }
  }
}
</style>
