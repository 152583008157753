import { createFetch } from '@vueuse/core'
import get from 'lodash/get'
import useApiUrl from '~/middleware/utils/useApiUrl'
import useToken from '~/middleware/utils/useToken'
import { Endpoints } from '~/middleware/utils/useApiUrl/types'
import useAppConfig from '~/composables/useAppConfig/index'
import { Persona } from '~/middleware/utils/useFetch/types'
import { CypressLocalStorage } from '~cypress/types'

interface UseFetchProps {
  endpoint?: Endpoints
  needAuthorization?: boolean
  hasMockData?: boolean
  persona?: Persona
  tenant?: string
}

// timeout to abort API request that lasts longer
export const DEFAULT_TIMEOUT = 10000

const useFetch = ({
  endpoint = Endpoints.BFF,
  needAuthorization = false,
  hasMockData = false,
  persona = Persona.KRISTINA,
  tenant
}: UseFetchProps) => {
  const apiUrl = useApiUrl(endpoint)
  const appConfig = useAppConfig()

  const tokenPromise = useToken(appConfig)

  return createFetch({
    baseUrl: apiUrl,
    options: {
      async beforeFetch({ options }) {
        if (needAuthorization) {
          const token = await tokenPromise

          if (token) {
            options.headers = {
              ...options.headers,
              Authorization: `Bearer ${token}`
            }
          }

          if (tenant) {
            options.headers = {
              ...options.headers,
              Tenant: tenant
            }
          }
        }

        if (hasMockData) {
          options.headers = {
            ...options.headers,
            'Persona': persona,
            'Subscription-Key': get(appConfig, 'BFF_SUBSCRIPTION_KEY', '')
          }
        } else if ((window as any).Cypress && localStorage.getItem(CypressLocalStorage.PERSONA)) {
          options.headers = {
            ...options.headers,
            'Persona': localStorage.getItem(CypressLocalStorage.PERSONA)!
          }
        }

        return { options }
      },
      onFetchError(ctx) {
        return ctx
      }
    }
  })
}

export default useFetch
