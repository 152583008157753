import { defineStore } from 'pinia'
import { StoreKeys } from '~/stores/types'

export interface State {
  pageTitle: string
  hasStickyHeader: boolean

  activeDialogFlag: boolean

  referrerURL: string
}

interface Actions {
  activateStickyDetailPage: (pageTitle: string) => void
  inactivateStickyDetailPage: () => void

  registerActiveDialog: () => void
  unregisterActiveDialog: () => void

  setReferrerURL: (referrerURL: string) => void
}

const useAppStore = defineStore<StoreKeys, State, any, Actions>(StoreKeys.APP, {
  state: () => ({
    pageTitle: '',
    hasStickyHeader: false,

    activeDialogFlag: false,
    referrerURL: ''
  }),

  getters: {
    hasActiveDialog: (state: State) => {
      return state.activeDialogFlag
    }
  },

  actions: {
    activateStickyDetailPage(title: string) {
      this.pageTitle = title
      this.hasStickyHeader = true
    },
    inactivateStickyDetailPage() {
      this.pageTitle = ''
      this.hasStickyHeader = false
    },

    registerActiveDialog() {
      this.activeDialogFlag = true
    },
    unregisterActiveDialog() {
      this.activeDialogFlag = false
    },

    setReferrerURL(referrerURL: string) {
      this.referrerURL = referrerURL
    }
  }
})

export default useAppStore
