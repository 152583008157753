export enum GLOBAL_EVENTS_GROUP {
  USER = 'user'
}

export enum GLOBAL_EVENTS {
  USER_TENANT_DEFINED = 'userTenantDefined',
  USER_TENANT_UNKNOWN = 'userTenantUnknown',
  USER_TENANT_SENEC = 'userTenantSenec',
}

export const useGlobalEvents = (eventGroup: GLOBAL_EVENTS_GROUP) => {
  return useEventBus<string>(eventGroup)
}
