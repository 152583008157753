import { ref, computed } from 'vue'
import { useScriptTag } from '@vueuse/core'

import useAppConfig from '~/composables/useAppConfig'
import { ONE_TRUST_COOKIE_ROUTE_BLACKLIST } from '~/utils/constants/oneTrustRouteBlacklist'

export type WindowWithOneTrust = Window & {
  OneTrust: {
    ToggleInfoDisplay: () => void
    IsAlertBoxClosedAndValid: () => boolean
  },
  OptanonWrapper: () => void
}

declare const window: WindowWithOneTrust

const isScriptLoaded = ref(false)
const isConsentAcknowledged = ref(false)

export interface CookieConsentComposable {
  isScriptLoaded: ComputedRef<boolean>
  isConsentAcknowledged: ComputedRef<boolean>
  shouldInjectScript: (route: string) => boolean
  injectScript: () => Promise<void>
  openCookieSettings: () => void
}

const useCookieConsent = (): CookieConsentComposable => {
  const getDomainId = () => {
    const appConfig = useAppConfig()
    return appConfig?.ONE_TRUST_COOKIE_DOMAIN
  }

  function shouldInjectScript(route: string) {
    return !ONE_TRUST_COOKIE_ROUTE_BLACKLIST.includes(route)
  }

  async function injectScript(): Promise<void> {
    const { load } = useScriptTag(
      'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      undefined,
      {
        manual: true,
        attrs: {
          'data-language': 'de',
          'data-domain-script': getDomainId()
        }
      }
    )

    return new Promise((resolve, reject) => {
      window.OptanonWrapper = function () {
        isConsentAcknowledged.value = window.OneTrust.IsAlertBoxClosedAndValid()
        resolve()
      }

      load(true)
        .then((scriptLoadResult) => {
          isScriptLoaded.value = !!scriptLoadResult
        })
        .catch(() => {
          isScriptLoaded.value = false
          reject(new Error('OneTrust script injection failed!'))
        })
    })
  }

  function openCookieSettings() {
    window.OneTrust.ToggleInfoDisplay()
  }

  return {
    isScriptLoaded: computed(() => isScriptLoaded.value),
    isConsentAcknowledged: computed(() => isConsentAcknowledged.value),

    shouldInjectScript,
    injectScript,
    openCookieSettings
  }
}

export default useCookieConsent
