import type { UseFetchReturn } from '@vueuse/core'

import useCustomerProfileApi from '~api/useCustomerProfile'
import useUserStore from '~/stores/user'
import { CustomerProfileData } from '~/stores/user/types'
import { Cache } from './types'

export const INITIAL_CUSTOMER_PROFILE = {
  personalData: {
    name: '',
    birthday: '',
    avatarContent: ''
  },
  communicationChannelData: {
    emailCount: 0,
    contactEmailAddresses: [],
    contractEmailAddresses: [],
    phoneNumberCount: 0,
    contactPhoneNumbers: [],
    contractPhoneNumbers: []
  },
  contractData: {
    contracts: []
  },
  paymentData: {
    selfPayments: [],
    contractBankAccounts: [],
    selfPaymentsWithBankAccount: []
  },
  addressData: {
    billingAddresses: [],
    contactAddresses: [],
    deliveryAddresses: []
  }
}

let request: null | UseFetchReturn<CustomerProfileData> = null

export default async (cacheState = Cache.Enabled): Promise<CustomerProfileData> => {
  const userStore = useUserStore()

  if (cacheState === Cache.Enabled && userStore.customerProfileData) {
    return userStore.customerProfileData
  }

  userStore.setIsCustomerProfileDataLoading(true)

  const abortAfterDefaultTimeout = true
  const tenant = userStore.tenantId

  // make sure only one request is made
  // when request for customer profile data comes after one request started
  if (!request) {
    request = useCustomerProfileApi({ abortAfterDefaultTimeout, tenant }) as UseFetchReturn<CustomerProfileData>
  }
  const { data, error } = await request

  if (error.value && error.value.name === 'AbortError') {
    request = null
    throw error.value
  }

  if (!data.value || !data.value?.personalData) {
    return INITIAL_CUSTOMER_PROFILE
  }

  const customerProfile = data.value as CustomerProfileData
  userStore.setCustomerProfileData(customerProfile)
  userStore.setIsCustomerProfileDataLoading(false)

  request = null

  return customerProfile
}
