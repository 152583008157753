import { ToastNotificationType } from '~/utils/registerNotificationTypes/types'

const getResult = (toastType: string|undefined): string => {
  switch(toastType){
    case ToastNotificationType.NEGATIVE: return 'failure'
    case ToastNotificationType.PARTIAL_POSITIVE: return 'partial-success'
    case ToastNotificationType.POSITIVE: return 'success'
    default: return ''
  }
}

const getReferrerBackURL = (referrerURL: string, toastType: string|undefined = undefined): string => {
  if(referrerURL){
    const baseReferrerURL = referrerURL.startsWith('https') ? referrerURL : `https://${referrerURL}`
    if(toastType){
      const paramInfo = `referrerResult=${getResult(toastType)}`
      const encodedParam = btoa(paramInfo)
      return referrerURL.includes('?') ? `${baseReferrerURL}${encodedParam}` : `${baseReferrerURL}?${encodedParam}`
    } else {
      return baseReferrerURL
    }
  } else
   return referrerURL
}

export default getReferrerBackURL
