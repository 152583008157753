import { CypressLocalStorage } from '~cypress/types'

import useAuth from '~/middleware/utils/useAuth'
import { DeployConfig } from '~/composables/useAppConfig'

let getAccessTokenPromise: Promise<string> | null

const useToken = (appConfig: DeployConfig) => {
  const auth = useAuth(appConfig)

  if (!auth) {
    return ''
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!(window as any).Cypress) {
    if (!getAccessTokenPromise) {
      const { getAccessTokenSilently } = auth
      getAccessTokenPromise = getAccessTokenSilently()
    }

    return getAccessTokenPromise
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const auth0Token = JSON.parse(localStorage.getItem(CypressLocalStorage.AUTH0)!)
    return auth0Token.body.access_token
  } catch(error) {
    // error handler
  }
}

export default useToken
