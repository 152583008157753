import { Endpoints } from './types'
import useAppConfig from '~/composables/useAppConfig'

const useApiUrl = (endpoint: Endpoints = Endpoints.BFF) => {
  const appConfig = useAppConfig()

  return endpoint === Endpoints.BFF
    ? `${appConfig?.APIM_URL}${appConfig?.BFF_API}`
    : appConfig?.AUTH0_DOMAIN
}

export default useApiUrl
