import type { ComputedRef } from 'vue'

import useDialogsStore from '~/stores/dialogs'
import { DialogId } from './types'

export interface DialogComposable {
  isOpen: ComputedRef<boolean>

  open: () => void
  close: () => void
}

export const DIALOG_BREAKPOINT = 698

export const useDialog = (dialogId: DialogId): DialogComposable => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogsStore()

  const isOpen = computed(() => isDialogOpen(dialogId))

  const open = () => {
    openDialog(dialogId)
  }

  const close = () => {
    closeDialog(dialogId)
  }

  return {
    isOpen,

    open,
    close
  }
}
