import { RouteLocationNormalized } from 'vue-router'
import { has } from 'lodash'

import {
  GaSnippet,
  PageTrackingParams, PageTrackingMode, PageTrackingImpersonationParams,
  GA_CUSTOM_PAGE_VIEW_EVENT_NAME
} from './types'

function createScript(src: string) {
  const script = document.createElement('script')
  script.innerHTML = src

  return script
}

export function initialize(gtmId: string, dataLayer = true) {
  if (dataLayer) document.head.appendChild(createScript(GaSnippet.GTM_DATA_LAYER))

  document.head.insertBefore(createScript(GaSnippet.GTM.replace('GTM_ID', gtmId)), document.head.childNodes[0])
}

export interface GoogleTagManagerComposable {
  pushToDataLayer: (payload: Record<string, unknown>) => void

  requestPageViewTracking: (route: RouteLocationNormalized, trackingParams: PageTrackingParams) => void
}

export type WindowWithGtmDataLayer = Window & {
  dataLayer: Record<string, unknown>[]
}

declare const window: WindowWithGtmDataLayer

export const useGoogleTagManager = (_gtmEnv: string): GoogleTagManagerComposable => {
  /**
   * Pushes event to data layer to be process by GTM.
   *
   * @param {Record<string, unknown>} payload
   *
   */
  function pushToDataLayer(payload: Record<string, unknown>): void {
    window.dataLayer.push(payload)
  }

  function trackPageView(pagePath: string) {
    pushToDataLayer({
      event: GA_CUSTOM_PAGE_VIEW_EVENT_NAME,
      page_path: pagePath
    })
  }

  function requestPageViewTracking(
    route: RouteLocationNormalized,
    trackingParams: PageTrackingParams
  ) {
    // prevent page view tracking in Cypress environment
    if ((window as any).Cypress) return

    if (trackingParams.mode === PageTrackingMode.DEFAULT) {
      trackPageView(route.path)
    } else if (trackingParams.mode === PageTrackingMode.IMPERSONATE_ROUTE_PARAMS) {
      // see README.md how impersonation params should be forwarded
      if (Object.keys(route.params).length && !trackingParams.impersonation) throw new Error('impersonation attributes are missing')

      const isReadyForTracking = !Object.keys(route.params).length ||
        !trackingParams?.impersonation ||
        (Object.keys(route.params).length === Object.keys(trackingParams?.impersonation).length &&
          Object.keys(trackingParams?.impersonation).every(key => Object.hasOwn(route.params, key))
        )
      if (!isReadyForTracking) throw new Error('impersonation attributes are not correct')

      // prepare tracking event data
      // anonymise route params
      const pagePath = Object.keys(route.params).reduce((impersonatedRoutePath: string, paramKey: string) => {
        if (has(trackingParams?.impersonation, paramKey)) {
          const replacement = (trackingParams?.impersonation as PageTrackingImpersonationParams)[paramKey] === paramKey
            ? route.params[paramKey] as string
            : (trackingParams?.impersonation as PageTrackingImpersonationParams)[paramKey]
          return impersonatedRoutePath.replace(route.params[paramKey] as string, replacement)
        }

        return impersonatedRoutePath
      }, route.path)
      trackPageView(pagePath)
    }
  }

  return {
    pushToDataLayer,
    requestPageViewTracking
  }
}
