import type { UseFetchOptions, UseFetchReturn } from '@vueuse/core'

import useFetch, { DEFAULT_TIMEOUT } from '~/middleware/utils/useFetch'
import { API_URLS } from '~/middleware/utils/useFetch/types'
import useAppConfig from '~/composables/useAppConfig/index'
import { CustomerProfileData } from '~/stores/user/types'
import { TenantId } from '~/composables/domain/useTenantConfig/types'

interface UseCustomerProfileOptions {
  abortAfterDefaultTimeout?: boolean
  tenant?: TenantId
}

const useCustomerProfile = ({ abortAfterDefaultTimeout, tenant }: UseCustomerProfileOptions = { abortAfterDefaultTimeout: false, tenant: undefined }): UseFetchReturn<CustomerProfileData> => {
  const appConfig = useAppConfig()

  const apiFetch = useFetch({
    needAuthorization: true,
    hasMockData: Boolean(appConfig?.IS_CMDM_MOCKED),
    persona: appConfig?.MOCK_DATA_PERSONA,
    tenant
  })

  const useFetchOptions: UseFetchOptions = {}

  if (abortAfterDefaultTimeout) {
    useFetchOptions.timeout = DEFAULT_TIMEOUT
  }

  return apiFetch(API_URLS.GET_CUSTOMER_PROFILE, useFetchOptions).json()
}

export default useCustomerProfile
